import React, {
  Component,
  useState,
  useEffect,
  useContext,
  forwardRef,
} from "react";
import {
  Card,
  Col,
  OverlayTrigger,
  Row,
  
  Table,
} from "react-bootstrap";
import Tooltip from '../tooltip/Tooltip';
import "datatables.net-dt/js/dataTables.dataTables.js";
import { AES, enc } from "crypto-js";
import { API_URL } from "../config";
import { IoIosHelpCircle } from "react-icons/io";
import { parse, format } from "date-fns";
import "datatables.net-responsive-dt";
import RecentItem from "../recentitem/rececentItem";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "react-data-table-component-extensions/dist/index.css";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { TokenContext } from "../AuthProvider";
import Header from "../Header";
import Footer from "../Footer";
import {
  FaEnvelope,
  FaPhoneSquareAlt,
  FaMobileAlt,
  FaFax,
} from "react-icons/fa";
import axios from "axios";
import { BsFillEnvelopeFill } from "react-icons/bs";
import { FaPencilAlt, FaTrashAlt, FaPlus } from "react-icons/fa";
// import { decryptedAccessToken, encryptedAccessToken } from "../../Token";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import DatePicker from "react-datepicker";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import moment from "moment";
const ViewOpportunity = (props) => {
  const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  const encryptedAccessToken = localStorage.getItem("access_token");
  const decryptedAccessToken = encryptedAccessToken
    ? AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8)
    : null;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [endDateError, setEndDateError] = useState("");
  setTimeout(() => {
    $("#quotes").DataTable();
    $("#tcf").DataTable();
    $("#shipment").DataTable();
    $("#history").DataTable();
    $("#historys").DataTable();
  }, 50);
  const [startDate, setStartDate] = useState(null);
  const [error, setError] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [accountNamedrop, setAccountnameDropdown] = useState("");
  const [opportunitydrop, setOpportunityDropdown] = useState("");
  const birthDate = startDate
    ? `${startDate.getDate().toString().padStart(2, "0")}/${(
        startDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${startDate.getFullYear()}`
    : null;
  console.log("startDate:", birthDate);

  const BankDate = endDate
    ? `${endDate.getDate().toString().padStart(2, "0")}/${(
        endDate.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}/${endDate.getFullYear()}`
    : null;
  console.log("startDate:", birthDate);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setError(null);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setError(null);
  };

  const [startDate1, setStartDate1] = useState(new Date());
  const CustomInput = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [contract, setContract] = useState([]);
  useEffect(() => {
    fetchDatacontract();
  }, [decryptedAccessToken]);

  const fetchDatacontract = async () => {
    try {
      const response = await axios.get(API_URL + "get/all/contract", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data.contract;
      console.log(data);
      setContract(data);
      $(document).ready(function () {
        // $('#tables').DataTable().destroy();
        // setTimeout(()=>{
        $("#tables").DataTable();
        // },500)
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  useEffect(() => {
    setIsButtonEnabled(true);
  }, []);
  const [rwoodusers, setRwoodusers] = useState([]);

  const fetchData3 = async () => {
    try {
      const response = await axios.get(API_URL + "get/all/rwoodusers", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });

      console.log("siva", response);
      const data = response.data.rwoodusers;
      setRwoodusers(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (decryptedAccessToken) {
      fetchData3();
    }
  }, [decryptedAccessToken]);

  const [accountName, setAccountName] = useState([]);
  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  const fetchDataSupplier = async () => {
    try {
      const response = await axios.get(API_URL + "all/accounts", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data.accounts;
      setAccountName(data);
      // console.log("Supplier" + JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  };

  const params = useParams();
  const id = params.id;
  const navigate = useNavigate();

  const handleOppShipClick = () => {
    navigate(`/opp/opportunity-shipment/${id}`);
  };
  const userRole = localStorage.getItem("role");
  // console.log(userRole);
  const { username, token } = useContext(TokenContext);
  const [datas, setDatas] = useState([]);
  const [history, setHistory] = useState([]);
  const [company, setCompany] = useState([]);
  const [opp, setOpp] = useState([]);
  const [shipments, setShipments] = useState([]);
  const [tcf, setTcf] = useState([]);
  const [startDate2, setStartDate2] = useState(new Date());
  const [check1, setCheck1] = useState(false);
  const [supplier, setSupplier] = useState([]);
  useEffect(() => {
    fetchDataSupplier1();
  }, [decryptedAccessToken]);

  const fetchDataSupplier1 = async () => {
    try {
      const response = await axios.get(API_URL + "get/supplier", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data;
      setSupplier(data);
      // console.log("Supplier" + JSON.stringify(data));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchDataAccount();
  }, [decryptedAccessToken]);

  const fetchDataAccount = async () => {
    try {
      const response = await axios.get(API_URL + "get/all/rwoodcompany", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      const data = response.data.rwoodcompany;
      setCompany(data);
      // console.log(data);
    } catch (error) {
      console.error(error);
    }
  };

  // const BankDate =
  //   startDate2.toLocaleDateString("en-US", { year: "numeric" }) +
  //   "-" +
  //   startDate2.toLocaleDateString("en-US", { month: "2-digit" }) +
  //   "-" +
  //   startDate2.toLocaleDateString("en-US", { day: "2-digit" });
  const BanktoDate = forwardRef(({ value, onClick, onChange }, ref) => (
    <input
      value={value}
      className="custom-input"
      onClick={onClick}
      onChange={onChange}
      ref={ref}
    ></input>
  ));
  useEffect(() => {
    fetchDataopp();
  }, [decryptedAccessToken]);

  const fetchDataopp = async () => {
    try {
      const response = await axios.get(API_URL + "all/opportunity", {
        headers: { Authorization: `Bearer ${decryptedAccessToken}` },
      });
      console.log(response);
      const data = response.data.opportunity;
      setOpp(data);
    } catch (error) {
      console.error(error);
    }
  };

  const [formData, setFromdata] = useState({
    type_of_new_record: "",
  });

  const handleChange = (e) => {
    setFromdata({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const encryptedAccessToken = localStorage.getItem("access_token");
    const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
    if (encryptedAccessToken) {
      const decryptedAccessToken = AES.decrypt(
        encryptedAccessToken,
        secret_key
      ).toString(enc.Utf8);

      fetch(API_URL + "add/new/record", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          Authorization: `Bearer ${decryptedAccessToken}`,
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())

        .then((response) => {
          console.log(response);
          if (response.message === "record addedd successfully") {
            const record_type_new = response.account_type;
            toast.success("Selected " + record_type_new, {
              position: "bottom-center",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            navigate("/opp/opportunity-shipment/" + id, {
              state: { record_type: record_type_new },
            });
          }
        });
    }
  };
  const [dataOpp, setDataOpp] = useState([]);
  const [data, setData] = useState({});
  const [lengths, setLength] = useState([]);
  const [length1, setLength1] = useState([]);
  const [data1, setData1] = useState([]);
  const [inquiryDate, setInquiry] = useState("");
  const [closeDate, setCloseDate] = useState("");
  // console.log(lengths)
  useEffect(() => {
    fetchData();
  }, [decryptedAccessToken]);
  const fetchData = async () => {
    try {
      const response = await axios.get(
        API_URL + "all/opportunity/datas/byID/" + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        console.log(response);
        setAccountnameDropdown(data[0].opportunity_details.account_name);
        setOpportunityDropdown(data[0].opportunity_details.opportunity_name);
        const dataLength = parseInt(response.data[1].opp_ship_length, 10);
        // const inquiryDate=parse(data[0].opportunity_details.inquiry_date, 'dd/MM/yyyy', new Date());
        const inquiryDate =
          data[0].opportunity_details.inquiry_date != null
            ? parse(
                data[0].opportunity_details.inquiry_date,
                "dd/MM/yyyy",
                new Date()
              )
            : "";
        setInquiry(inquiryDate);
        // const close_date=parse(data[0].opportunity_details.close_date, 'dd/MM/yyyy', new Date());
        const close_date =
          data[0].opportunity_details.close_date != null
            ? parse(
                data[0].opportunity_details.close_date,
                "dd/MM/yyyy",
                new Date()
              )
            : "";
        setCloseDate(close_date);
        console.log("data == " + JSON.stringify(data));
        console.log("inquiryDate == " + data[0].opportunity_details);
        setLength(dataLength);
        setData1(data[0].opportunity_details);
        setLength1(data.length);
        console.log(data.length);
        setDataOpp(data[0].opportunity_details);
        setDatas(data[0].quotes);
        setShipments(data[0].opportunity_shipments);
        setTcf(data[0].tcf);
        console.log(data[0].tcf);
        setHistory(data[0].history);
        setTimeout(() => {
          $("#quotes").DataTable();
          $("#tcf").DataTable();
          $("#shipment").DataTable();
          $("#historys").DataTable();
          $("#history").DataTable();
        }, 50);
      } else {
        toast.error("Do not have Data", {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const options_1 = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", options_1).format(number);
  };

  const renderShipmentQty = (tcfValues) => {
    if (tcfValues > 0) {
      return <span className="sr">{formatNumber(tcfValues)}</span>;
    } else if (tcfValues < 0) {
      return <span className="sr">({formatNumber(Math.abs(tcfValues))})</span>;
    } else {
      return <span className="sr">{formatNumber(tcfValues)}</span>;
    }
  };

  useEffect(() => {
    fetchDataSupplier();
  }, [decryptedAccessToken]);

  useEffect(() => {
    // Set the selected option to 'Siva' when accountName changes
    if (accountNamedrop.length > 0 && opportunitydrop.length > 0) {
      setAccountnameDropdown({
        value: accountNamedrop,
        label: accountNamedrop,
      });
      setOpportunityDropdown({
        value: opportunitydrop,
        label: opportunitydrop,
      });
    }
  }, [accountNamedrop, opportunitydrop]);

  const options = accountName.map((account) => ({
    value: account.account_name,
    label: account.account_name,
  }));
  const optionsOpportunity = contract.map((x) => ({
    value: x.account_name,
    label: x.account_name,
  }));

  const handleInquiryChange = (date) => {
    setInquiry(date);
    if (endDate && date > endDate) {
      setEndDateError("Close Date cannot be before Inquiry Date");
    } else {
      setEndDateError("");
    }
  };

  const handleCloseChange = (date) => {
    setCloseDate(date);
    if (startDate && date < startDate) {
      setEndDateError("Close Date cannot be before Inquiry Date");
    } else {
      setEndDateError("");
    }
  };

  const [lockeds, setLockeds] = useState(null);
  const check_box = data1.locked;
  console.log(check_box);
  useEffect(() => {
    setLockeds(check_box);
  }, [check_box]); // Run this effect whenever tt changes

  useEffect(() => {
    console.log("Value of lockeds:", lockeds); // Log lockeds
  }, [lockeds]); // Run

  const handleCheckboxChange = (e) => {
    setLockeds(e.target.checked);
  };

  const handleEndDateInputChange = (e) => {
    const inputValue = e.target.value;
    const inputDate = moment(inputValue, "DD/MM/YYYY", true);
    if (!inputDate.isValid()) {
      setEndDateError("Invalid date format");
    } else if (startDate && inputDate.toDate() < startDate) {
      setEndDateError("Close Date cannot be before Inquiry Date");
    } else {
      setEndDateError("");
      setEndDate(inputDate.toDate());
    }
  };

  useEffect(() => {
    fetchData1();
  }, [decryptedAccessToken]);

  const fetchData1 = async () => {
    try {
      const response = await axios.get(
        API_URL + "get/all/offer_quote_byID/" + id,
        {
          headers: { Authorization: `Bearer ${decryptedAccessToken}` },
        }
      );
      const data = response.data;
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const addOffer = () => {
    console.log(lengths);

    if (lengths > 0) {
      if (
        data1.opportunity_record_type === "Buyer - Spot" ||
        data1.opportunity_record_type === "Supplier - Spot"
      ) {
        const encryptedAccessToken = localStorage.getItem("access_token");
        const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(
            encryptedAccessToken,
            secret_key
          ).toString(enc.Utf8);

          fetch(`${API_URL}add/new_quotes/${id}`, {
            method: "POST",
            headers: {
              Authorization: `Bearer ${decryptedAccessToken}`,
              "Content-Type": "application/json",
            },
          })
            .then((response) => response.json())
            .then((response) => {
              console.log(response);
              const quoteId = response.quote_id;
              console.log("Quotes Created", response);

              fetch(`${API_URL}add/quoteshipment/${id}/${quoteId}`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${decryptedAccessToken}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())
                .then((response) => {
                  console.log("Quoteshipment Created", response);
                });

              fetch(`${API_URL}add/tcf_new/${quoteId}`, {
                method: "POST",
                headers: {
                  Authorization: `Bearer ${decryptedAccessToken}`,
                  "Content-Type": "application/json",
                },
              })
                .then((response) => response.json())
                .then((response) => {
                  console.log("TCF Created", response);
                  toast.success("TCF & Quotes Created Successfully", {
                    position: "bottom-center",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                  });
                  setTimeout(() => {
                    // navigate(`/opportunity/quote-view/${quoteId}`);
                  }, 2000);
                });
            });
        }
      } else if (
        data1.opportunity_record_type === "Buyer - Long Term" ||
        data1.opportunity_record_type === "Supplier - Long Term"
      ) {
        const encryptedAccessToken = localStorage.getItem("access_token");
        const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
        // if (encryptedAccessToken) {
        //     const decryptedAccessToken = AES.decrypt(
        //         encryptedAccessToken,
        //         secret_key
        //     ).toString(enc.Utf8);

        //     fetch(`${API_URL}add/new_quotes/${id}`, {
        //       method: "POST",
        //       headers: {
        //           Authorization: `Bearer ${decryptedAccessToken}`,
        //           "Content-Type": "application/json",
        //       },
        //   })
        //   .then((response) => response.json())
        //       .then((response) => {
        //           console.log(response)
        //             const quoteId = response.quote_id;
        //             console.log('Quotes Created',response);

        //             fetch(`${API_URL}add/quoteshipment/${id}/${quoteId}`, {
        //                 method: "POST",
        //                 headers: {
        //                     Authorization: `Bearer ${decryptedAccessToken}`,
        //                     "Content-Type": "application/json",
        //                 },
        //             })
        //                 .then((response) => response.json())
        //                 .then((response) => {
        //                     console.log('Quoteshipment Created',response);
        //                 });

        //             fetch(`${API_URL}add/tcf_new/${quoteId}`, {
        //                 method: "POST",
        //                 headers: {
        //                     Authorization: `Bearer ${decryptedAccessToken}`,
        //                     "Content-Type": "application/json",
        //                 },
        //             })
        //                 .then((response) => response.json())
        //                 .then((response) => {
        //                   console.log('TCF Created',response);
        //                     toast.success("TCF & Quotes Created Successfully", {
        //                         position: "bottom-center",
        //                         autoClose: 1000,
        //                         hideProgressBar: false,
        //                         closeOnClick: true,
        //                         pauseOnHover: true,
        //                         draggable: true,
        //                         progress: undefined,
        //                         theme: "light",
        //                     });
        //                     // console.log(id,data1.opportunity_record_type)
        //                     setTimeout(() => {
        //                       navigate("/opportunity/buyer-supplier", {
        //                         state: { id: id, record_type: data1.opportunity_record_type },
        //                     });
        //                     }, 2000);
        //                 });
        //         });
        // }
        setTimeout(() => {
          navigate("/opportunity/buyer-supplier", {
            state: { id: id, record_type: data1.opportunity_record_type },
          });
        }, 2000);
      }
    } else {
      toast.success("Add Opportunity Shipment First", {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  // else if(data1.opportunity_record_type ==='Buyer - Long Term'){
  //   const encryptedAccessToken = localStorage.getItem("access_token");
  //       const secret_key = "4d3a23fba54bbef1e14dd44ba9591612";
  //       if (encryptedAccessToken) {
  //         const decryptedAccessToken = AES.decrypt(
  //           encryptedAccessToken,
  //           secret_key
  //         ).toString(enc.Utf8);

  //         fetch(`${API_URL}add/new_quotes/${id}`, {
  //           method: "POST",
  //           headers: {
  //             Authorization: `Bearer ${decryptedAccessToken}`,
  //             "Content-Type": "application/json",
  //           },
  //         })
  //           .then((response) => response.json())

  //           .then((response) => {
  //             const quoteId = response.id;

  //               fetch(`${API_URL}add/tcf_new/${id}`, {
  //                 method: "POST",
  //                 headers: {
  //                   Authorization: `Bearer ${decryptedAccessToken}`,
  //                   "Content-Type": "application/json",
  //                 },
  //               })
  //                 .then((response) => response.json())

  //                 .then((response) => {
  //                   console.log(response);
  //                   toast.success("TCF & Quotes Created Successfully", {
  //                     position: "bottom-center",
  //                     autoClose: 1000,
  //                     hideProgressBar: false,
  //                     closeOnClick: true,
  //                     pauseOnHover: true,
  //                     draggable: true,
  //                     progress: undefined,
  //                     theme: "light",
  //                   });
  //                   // setTimeout(() => {
  //                   //   navigate("/opportunity/quote-view/" + quoteId);
  //                   // }, 2000);
  //                 });

  //           });
  //       }
  // }

  const [isEditMode, setIsEditMode] = useState(false);
  const [editedData, setEditedData] = useState({});
  const [surveyors, setSurveyor] = useState([]);
  const handleEditClick = () => {
    setIsEditMode(true);
    setEditedData(data);
    setTimeout(() => {
      $("#quotes").DataTable();
      $("#tcf").DataTable();
      $("#shipment").DataTable();
      $("#history").DataTable();
      $("#historys").DataTable();
    }, 50);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setData1((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCancelEdit = () => {
    setIsEditMode(false);
    setEditedData(data);
    setTimeout(() => {
      $("#quotes").DataTable();
      $("#tcf").DataTable();
      $("#shipment").DataTable();
      $("#history").DataTable();
      $("#historys").DataTable();
    }, 50);
  };
  const handleSaveEdit = (e) => {
    e.preventDefault();
    if (data1.opportunity_record_type === "Buyer") {
      setIsSubmitted(true);
      const responseData = {
        company: data1.company,
        exchange_rate: data1.exchange_rate,
        currency: data1.currency,
        opportunity_name: opportunitydrop.value,
        // "account_name": accountNamedrop.value,
        account_name: data1.account_name,
        supplier: data1.supplier,
        trader_name: data1.trader_name,
        inquiry_date: inquiryDate ? format(inquiryDate, "dd/MM/yyyy") : null,
        close_date: closeDate ? format(closeDate, "dd/MM/yyyy") : null,

        opportunity_owner: data1.opportunity_owner,
        stage: data1.stage,
        primary_campaign_source: data1.primary_campaign_source,
        no_of_opp_shipments: data1.no_of_opp_shipments,
        laycan_year: data1.laycan_year,
        laycan_month: data1.laycan_month,
        locked: lockeds,
        opportunity_record_type: data1.opportunity_record_type,
        probability: data1.probability,
        no_of_trade_confirmation: data1.no_of_trade_confirmation,
        loss_reason: data1.loss_reason,
        loss_reason_remark: data1.loss_reason_remark,
        no_of_order: data1.no_of_order,
        commodity: data1.commodity,
        destination: data1.destination,
        origin: data1.origin,
        quantity_mt: data1.quantity_mt,
        quantity: data1.quantity,
        product_description: data1.product_description,
        amount: data1.amount,
        contract: data1.contract,
        bonus_penalty_clause: data1.bonus_penalty_clause,
        rejections: data1.rejections,
        payment_terms: data1.payment_terms,
        validity: data1.validity,
        applicable_law: data1.applicable_law,
        synced_quote_id: data1.synced_quote_id,
        next_step: data1.next_step,
        description: data1.description,
      };
      console.log(JSON.stringify(responseData));
      e.preventDefault();

      axios
        .put(`${API_URL}opportunity/update/${id}`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          fetchData();
          if (response.data.success === true) {
            setIsSubmitted(false);
            document.getElementById("messageContainer").innerHTML = `
              <div class="success-message">
              ${response.data.msg}
              </div>
          `;

            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: animated scrolling
            });
            setData(response);
            setIsEditMode(false);
          } else if (response.data.success === false) {
            document.getElementById("messageContainer").innerHTML = `
              <div class="error-message">
                  ${response.data.msg}
              </div>
          `;

            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: animated scrolling
            });
          }
        })
        .catch((error) => console.error(error));
    }

    if (data1.opportunity_record_type === "Buyer - Spot") {
      setIsSubmitted(true);
      const responseData = {
        company: data1.company,
        exchange_rate: data1.exchange_rate,
        currency: data1.currency,
        opportunity_name: opportunitydrop.value,
        account_name: data1.account_name,
        supplier: data1.supplier,
        trader_name: data1.trader_name,
        inquiry_date: inquiryDate ? format(inquiryDate, "dd/MM/yyyy") : null,
        close_date: closeDate ? format(closeDate, "dd/MM/yyyy") : null,
        stage: data1.stage,
        primary_campaign_source: data1.primary_campaign_source,
        no_of_opp_shipments: data1.no_of_opp_shipments,
        laycan_year: data1.laycan_year,
        laycan_month: data1.laycan_month,
        locked: lockeds,
        opportunity_record_type: data1.opportunity_record_type,
        opportunity_owner: data1.opportunity_owner,
        probability: data1.probability,
        no_of_trade_confirmation: data1.no_of_trade_confirmation,
        loss_reason: data1.loss_reason,
        loss_reason_remark: data1.loss_reason_remark,
        no_of_order: data1.no_of_order,
        commodity: data1.commodity,
        destination: data1.destination,
        origin: data1.origin,
        quantity_mt: data1.quantity_mt,
        quantity: data1.quantity,
        product_description: data1.product_description,
        amount: data1.amount,
        contract: data1.contract,
        bonus_penalty_clause: data1.bonus_penalty_clause,
        rejections: data1.rejections,
        payment_terms: data1.payment_terms,
        validity: data1.validity,
        applicable_law: data1.applicable_law,
        synced_quote_id: data1.synced_quote_id,
        next_step: data1.next_step,
        description: data1.description,
      };
      axios
        .put(`${API_URL}opportunity/update/${id}`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          fetchData();
          if (response.data.success === true) {
            setIsSubmitted(false);
            document.getElementById("messageContainer").innerHTML = `
                      <div class="success-message">
                      ${response.data.msg}
                      </div>
                  `;

            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: animated scrolling
            });
            setData(response);
            setIsEditMode(false);
          } else if (response.data.success === false) {
            document.getElementById("messageContainer").innerHTML = `
                      <div class="error-message">
                          ${response.data.msg}
                      </div>
                  `;

            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: animated scrolling
            });
          }
        })
        .catch((error) => console.error(error));
    }

    if (data1.opportunity_record_type === "Supplier") {
      const responseData = {
        company: data1.company,

        exchange_rate: data1.exchange_rate,
        currency: data1.currency,
        opportunity_name: opportunitydrop.value,
        account_name: data1.account_name,
        supplier: data1.supplier,
        trader_name: data1.trader_name,
        inquiry_date: inquiryDate ? format(inquiryDate, "dd/MM/yyyy") : null,
        close_date: closeDate ? format(closeDate, "dd/MM/yyyy") : null,
        stage: data1.stage,
        primary_campaign_source: data1.primary_campaign_source,
        no_of_opp_shipments: data1.no_of_opp_shipments,
        laycan_year: data1.laycan_year,
        laycan_month: data1.laycan_month,
        opportunity_record_type: data1.opportunity_record_type,
        opportunity_owner: data1.opportunity_owner,
        probability: data1.probability,
        no_of_trade_confirmation: data1.no_of_trade_confirmation,
        loss_reason: data1.loss_reason,
        loss_reason_remark: data1.loss_reason_remark,
        no_of_order: data1.no_of_order,
        commodity: data1.commodity,
        destination: data1.destination,
        origin: data1.origin,
        quantity_mt: data1.quantity_mt,
        quantity: data1.quantity,
        product_description: data1.product_description,
        amount: data1.amount,
        contract: data1.contract,
        bonus_penalty_clause: data1.bonus_penalty_clause,
        rejections: data1.rejections,
        payment_terms: data1.payment_terms,
        validity: data1.validity,
        applicable_law: data1.applicable_law,
        synced_quote_id: data1.synced_quote_id,
        next_step: data1.next_step,
        description: data1.description,
      };
      console.log(JSON.stringify(responseData));
      axios
        .put(`${API_URL}opportunity/update/${id}`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          fetchData();
          if (response.data.success === true) {
            document.getElementById("messageContainer").innerHTML = `
              <div class="success-message">
              ${response.data.msg}
              </div>
          `;

            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: animated scrolling
            });
            setData(response);
            setIsEditMode(false);
          } else if (response.data.success === false) {
            document.getElementById("messageContainer").innerHTML = `
              <div class="error-message">
                  ${response.data.msg}
              </div>
          `;

            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: animated scrolling
            });
          }
        })
        .catch((error) => console.error(error));
    }
    if (data1.opportunity_record_type === "Supplier - Long Term") {
      const responseData = {
        company: data1.company,

        exchange_rate: data1.exchange_rate,
        currency: data1.currency,
        opportunity_name: opportunitydrop.value,
        account_name: data1.account_name,
        supplier: data1.supplier,
        trader_name: data1.trader_name,
        inquiry_date: inquiryDate ? format(inquiryDate, "dd/MM/yyyy") : null,
        close_date: closeDate ? format(closeDate, "dd/MM/yyyy") : null,
        stage: data1.stage,
        primary_campaign_source: data1.primary_campaign_source,
        no_of_opp_shipments: data1.no_of_opp_shipments,
        laycan_year: data1.laycan_year,
        laycan_month: data1.laycan_month,
        opportunity_record_type: data1.opportunity_record_type,
        opportunity_owner: data1.opportunity_owner,
        probability: data1.probability,
        no_of_trade_confirmation: data1.no_of_trade_confirmation,
        loss_reason: data1.loss_reason,
        loss_reason_remark: data1.loss_reason_remark,
        no_of_order: data1.no_of_order,
        commodity: data1.commodity,
        destination: data1.destination,
        origin: data1.origin,
        quantity_mt: data1.quantity_mt,
        quantity: data1.quantity,
        product_description: data1.product_description,
        amount: data1.amount,
        contract: data1.contract,
        bonus_penalty_clause: data1.bonus_penalty_clause,
        rejections: data1.rejections,
        payment_terms: data1.payment_terms,
        validity: data1.validity,
        applicable_law: data1.applicable_law,
        synced_quote_id: data1.synced_quote_id,
        next_step: data1.next_step,
        description: data1.description,
      };
      console.log(JSON.stringify(responseData));
      axios
        .put(`${API_URL}opportunity/update/${id}`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          fetchData();
          if (response.data.success === true) {
            document.getElementById("messageContainer").innerHTML = `
              <div class="success-message">
              ${response.data.msg}
              </div>
          `;

            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: animated scrolling
            });
            setData(response);
            setIsEditMode(false);
          } else if (response.data.success === false) {
            document.getElementById("messageContainer").innerHTML = `
              <div class="error-message">
                  ${response.data.msg}
              </div>
          `;

            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: animated scrolling
            });
          }
        })
        .catch((error) => console.error(error));
    }
    if (data1.opportunity_record_type === "Supplier - Spot") {
      const responseData = {
        company: data1.company,

        exchange_rate: data1.exchange_rate,
        currency: data1.currency,
        opportunity_name: opportunitydrop.value,
        account_name: data1.account_name,
        supplier: data1.supplier,
        trader_name: data1.trader_name,
        inquiry_date: inquiryDate ? format(inquiryDate, "dd/MM/yyyy") : null,
        close_date: closeDate ? format(closeDate, "dd/MM/yyyy") : null,
        stage: data1.stage,
        primary_campaign_source: data1.primary_campaign_source,
        no_of_opp_shipments: data1.no_of_opp_shipments,
        laycan_year: data1.laycan_year,
        laycan_month: data1.laycan_month,
        opportunity_record_type: data1.opportunity_record_type,
        opportunity_owner: data1.opportunity_owner,
        probability: data1.probability,
        no_of_trade_confirmation: data1.no_of_trade_confirmation,
        loss_reason: data1.loss_reason,
        loss_reason_remark: data1.loss_reason_remark,
        no_of_order: data1.no_of_order,
        commodity: data1.commodity,
        destination: data1.destination,
        origin: data1.origin,
        quantity_mt: data1.quantity_mt,
        quantity: data1.quantity,
        product_description: data1.product_description,
        amount: data1.amount,
        contract: data1.contract,
        bonus_penalty_clause: data1.bonus_penalty_clause,
        rejections: data1.rejections,
        payment_terms: data1.payment_terms,
        validity: data1.validity,
        applicable_law: data1.applicable_law,
        synced_quote_id: data1.synced_quote_id,
        next_step: data1.next_step,
        description: data1.description,
      };
      console.log(responseData);
      axios
        .put(`${API_URL}opportunity/update/${id}`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          fetchData();
          if (response.data.success === true) {
            document.getElementById("messageContainer").innerHTML = `
            <div class="success-message">
            ${response.data.msg}
            </div>
        `;

            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: animated scrolling
            });
            setData(response);
            setIsEditMode(false);
          } else if (response.data.success === false) {
            document.getElementById("messageContainer").innerHTML = `
            <div class="error-message">
                ${response.data.msg}
            </div>
        `;

            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: animated scrolling
            });
          }
        })
        .catch((error) => console.error(error));
    }

    if (data1.opportunity_record_type === "Buyer - Long Term") {
      setIsSubmitted(true);
      const responseData = {
        company: data1.company,

        exchange_rate: data1.exchange_rate,
        currency: data1.currency,
        product_status: data1.product_status,
        opportunity_name: opportunitydrop.value,
        account_name: data1.account_name,
        supplier: data1.supplier,
        trader_name: data1.trader_name,
        inquiry_date: inquiryDate ? format(inquiryDate, "dd/MM/yyyy") : null,
        close_date: closeDate ? format(closeDate, "dd/MM/yyyy") : null,

        stage: data1.stage,
        primary_campaign_source: data1.primary_campaign_source,
        no_of_opp_shipments: data1.no_of_opp_shipments,
        laycan_year: data1.laycan_year,
        laycan_month: data1.laycan_month,
        locked: lockeds,
        opportunity_record_type: data1.opportunity_record_type,
        opportunity_owner: data1.opportunity_owner,
        probability: data1.probability,
        no_of_trade_confirmation: data1.no_of_trade_confirmation,
        loss_reason: data1.loss_reason || "",
        loss_reason_remark: data1.loss_reason_remark,
        no_of_order: data1.no_of_order,
        commodity: data1.commodity,
        destination: data1.destination,
        origin: data1.origin,
        quantity_mt: data1.quantity_mt,
        quantity: data1.quantity,
        product_description: data1.product_description,
        amount: data1.amount,
        contract: data1.contract,
        bonus_penalty_clause: data1.bonus_penalty_clause,
        rejections: data1.rejections,
        payment_terms: data1.payment_terms,
        validity: data1.validity,
        applicable_law: data1.applicable_law,
        synced_quote_id: data1.synced_quote_id,
        next_step: data1.next_step,
        description: data1.description,
      };
      console.log(responseData);
      axios
        .put(`${API_URL}opportunity/update/${id}`, responseData, {
          headers: {
            Authorization: `Bearer ${decryptedAccessToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          fetchData();
          if (response.data.success === true) {
            setIsSubmitted(false);
            document.getElementById("messageContainer").innerHTML = `
              <div class="success-message">
              ${response.data.msg}
              </div>
          `;

            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: animated scrolling
            });
            setData(response);
            setIsEditMode(false);
          } else if (response.data.success === false) {
            document.getElementById("messageContainer").innerHTML = `
              <div class="error-message">
                  ${response.data.msg}
              </div>
          `;

            // Scroll to the top of the page
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Optional: animated scrolling
            });
          }
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <div id="view-page">
      <Header />
      <div className="contactAccount  full-container">
        <div className="row">
          <div className="col-md-2 recent-item">
            <RecentItem />
          </div>
          <div className="col-md-10 right-section">
            <div className="page-header" id="add-account">
              <div>
                <h2 className="main-content-title tx-24 mg-b-5">
                  Opportunity Details
                </h2>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active" aria-current="page">
                    Dashboard / Opportunity / Opportunity Details
                  </li>
                </ol>
              </div>
            </div>

            {length1 > 0 ? (
              <>
                {data1.opportunity_record_type === "Buyer" ? (
                  <>
                    {isEditMode ? (
                      <div className="tasks oppEdit">
                        <div id="messageContainer"></div>
                        <p className="edit-btn">
                          <input
                            type="submit"
                            className="account-save"
                            onClick={handleSaveEdit}
                            value="Save"
                            //disabled={isSubmitted}
                          />
                          <button
                            className="btn btn-primary"
                            onClick={handleCancelEdit}
                          >
                            Cancel
                          </button>
                        </p>
                        {error && (
                          <p className="date-error">
                            <span>{error}</span>
                          </p>
                        )}
                        <Row className="mb-3">
                          <h4 className="heading">Opportunity Information</h4>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Company"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="company"
                                tabIndex={1}
                                placeholder="Company"
                                defaultValue={data1.company || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select</option>
                                {company.map((x) => {
                                  return (
                                    <option value={x.company_name}>
                                      {x.company_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <label>Locked</label>
                            <input
                              type="checkbox"
                              name="locked"
                              tabIndex={8}
                              checked={lockeds} // Use the state variable to determine if the checkbox is checked
                              onChange={handleCheckboxChange} // Handle the change event
                              className="mx-3"
                            />
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Exchange rate"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Exchange rate"
                                name="exchange_rate"

                                tabIndex={2}
                                defaultValue={data1.exchange_rate || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Opportunity record type"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Opportunity record type"
                                name="opportunity_record_type"
                                tabIndex={9}
                                className="opportunity-record-type"
                                defaultValue={
                                  data1.opportunity_record_type || ""
                                }
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              label="Opportunity name"
                              className="select-dropdown"
                            >
                              <Select
                                className="select"
                                tabIndex={3}
                                value={opportunitydrop}
                                onChange={setOpportunityDropdown}
                                options={optionsOpportunity}
                              />
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Currency"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="currency"
                                placeholder="Currency"
                                tabIndex={10}
                                defaultValue={data1.currency || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">---None---</option>
                                <option value="USD">USD</option>
                                <option value="IDR">IDR</option>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Opportunity Owner"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                tabIndex={4}
                                placeholder="Opportunity Owner"
                                name="opportunity_owner"
                                defaultValue={data1.opportunity_owner || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          {/* <Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="probability"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="probability"
                        name="probability"
                        defaultValue={data1.probability || ""}
                        onChange={handleChangeInput}
                      />
                    </FloatingLabel>
                  </Form.Group> */}

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="No of trade confirmation"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="No of trade confirmation"
                                name="no_of_trade_confirmation"
                                tabIndex={11}
                                defaultValue={data1.no_of_trade_confirmation}
                                onChange={handleChangeInput}
                              />
                              {/* {errors.no_of_trade_confirmation && <span>Required</span>} */}
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="account name"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="account_name"
                                placeholder="account name"
                                tabIndex={5}
                                className="error-validation"
                                defaultValue={data1.account_name}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select</option>
                                {accountName.map((x) => {
                                  return (
                                    <option value={x.account_name}>
                                      {x.account_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Supplier"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="supplier"
                                placeholder="Supplier" disabled
                                tabIndex={12}
                                defaultValue={data1.supplier || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select</option>
                                {supplier.map((x) => {
                                  return (
                                    <option value={x.account_name}>
                                      {x.account_name}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Trader Name"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="trader_name"
                                tabIndex={6}
                                placeholder="Trader Name"
                                defaultValue={data1.trader_name || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">Select</option>
                                {rwoodusers.map((x) => {
                                  return (
                                    <option value={x.rwood_username}>
                                      {x.rwood_username}
                                    </option>
                                  );
                                })}
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                          {/* <Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="No of order"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="No of order"
                        name="no_of_order"
                        defaultValue={data1.no_of_order || ""}
                        onChange={handleChangeInput}
                      />
                    </FloatingLabel>
                  </Form.Group> */}
                          <Form.Group as={Col} md="6"></Form.Group>
                          {/* <Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Inquiry Date"
                      className="mb-3 datepick"
                    >
                      <DatePicker
                        selected={startDate1}
                        onChange={(date) => setStartDate1(date)}
                        customInput={<CustomInput />}
                        dayClassName={() => "example-datepicker-day-class"}
                        popperClassName="example-datepicker-class"
                        todayButton="TODAY"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Inquiry Date"
                      />
                    </FloatingLabel>
                  </Form.Group> */}

                          {/* <Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="No of trade confirmation"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="No of trade confirmation"
                        name="no_of_trade_confirmation"
                        defaultValue={data1.no_of_trade_confirmation || ""}
                        onChange={handleChangeInput}
                      />
                    </FloatingLabel>
                  </Form.Group> */}
                          {/* <Form.Group as={Col} md="6"></Form.Group>
                  <Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Close Date"
                      className="mb-3 datepick"
                    >
                      <DatePicker
                        selected={startDate2}
                        onChange={(date) => setStartDate2(date)}
                        customInput={<BanktoDate />}
                        dayClassName={() => "example-datepicker-day-class"}
                        popperClassName="example-datepicker-class"
                        todayButton="TODAY"
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Close Date"
                      />
                    </FloatingLabel>
                  </Form.Group> */}

                          {/* <Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Loss reason "
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Loss reason"
                        name="loss_reason"
                        defaultValue={data1.loss_reason || ""}
                        onChange={handleChangeInput}
                      />
                    </FloatingLabel>
                  </Form.Group> */}
                          <Form.Group as={Col} md="6"></Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Stage"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="stage"
                                tabIndex={13}
                                placeholder="Stage"
                                defaultValue={data1.stage || ""}
                                onChange={handleChangeInput}
                              >
                                <option>Select</option>
                                <option value="Proposal">Proposal</option>
                                <option value="Concluded">Concluded</option>
                                <option value="Quoted">Quoted</option>
                                <option value="Dropped">Dropped</option>
                                <option value="Last">Last</option>
                                <option value="Closed">Closed</option>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>

                          {/* <Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Primary campaign source"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Primary campaign source"
                        name="primary_campaign_source"
                        defaultValue={data1.primary_campaign_source || ""}
                        onChange={handleChangeInput}
                      />
                    </FloatingLabel>
                  </Form.Group> */}
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Laycan Year"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="laycan_year"
                                tabIndex={7}
                                placeholder="Laycan Year"
                                defaultValue={data1.laycan_year || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">---None---</option>
                                <option value="2021">2021</option>
                                <option value="2022">2022</option>
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2015">2015</option>
                                <option value="2019">2019</option>
                                <option value="2020">2020</option>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <h4 className="heading">Product Information</h4>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              abel="contract quantity"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                tabIndex={14}
                                placeholder="contract quantity"
                                name="quantity_mt"
                                defaultValue={data1.quantity_mt || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          {/* <Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Amount"
                      className="mb-3"
                    >
                      <Form.Control
                        type="text"
                        placeholder="Amount"
                        name="amount"
                        defaultValue={data1.amount || ""}
                        onChange={handleChangeInput}
                      />
                    </FloatingLabel>
                  </Form.Group> */}
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="shipment quantity "
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="shipment quantity"
                                name="quantity"
                                tabIndex={16}
                                defaultValue={data1.quantity || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract"
                            placeholder="Contract"
                            defaultValue={data1.contract || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">Select</option>
                            {contract.map((x) => {
                              return (
                                <option value={x.contract_number}>
                                  {x.contract_number}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group> */}
                          <Form.Group as={Col} md="12" className="tooltips">
                            <FloatingLabel
                              controlId="floatingTextarea2"
                              label="Product description"
                            >
                              <Form.Control
                                as="textarea"
                                placeholder="Product description"
                                style={{ height: "200px" }}
                                tabIndex={15}
                                name="product_description"
                                defaultValue={data1.product_description || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                            <Tooltip text={<span>Product Specification</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <h4 className="heading">Market Information</h4>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Origin"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                tabIndex={17}
                                placeholder="Origin"
                                name="origin"
                                defaultValue={data1.origin || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingTextarea2"
                              label="Destination"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Destination"
                                style={{ height: "200px" }}
                                name="destination"
                                tabIndex={18}
                                defaultValue={data1.destination || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Row>
                        {/* <Row className="mb-3">
                  <h4 className="heading">Price and Price Adjustment</h4>
                  <Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Bonus Penalty Clause"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Bonus Penalty Clause"
                        style={{ height: "200px" }}
                        name="bonus_penalty_clause"
                        defaultValue={data1.bonus_penalty_clause || ""}
                        onChange={handleChangeInput}
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Rejections"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Rejections"
                        style={{ height: "200px" }}
                        name="rejections"
                        defaultValue={data1.rejections || ""}
                        onChange={handleChangeInput}
                      />
                    </FloatingLabel>
                  </Form.Group>
                  <Form.Group as={Col} md="12">
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Payment terms"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Payment terms"
                        style={{ height: "200px" }}
                        name="payment_terms"
                        defaultValue={data1.payment_terms || ""}
                        onChange={handleChangeInput}
                      />
                    </FloatingLabel>
                  </Form.Group>

                  <Form.Group as={Col} md="12">
                    <FloatingLabel
                      controlId="floatingTextarea2"
                      label="Payment terms"
                    >
                      <Form.Control
                        as="textarea"
                        placeholder="Payment terms"
                        style={{ height: "200px" }}
                        name="payment_terms"
                        defaultValue={data1.payment_terms || ""}
                        onChange={handleChangeInput}
                      />
                    </FloatingLabel>
                  </Form.Group>
                </Row> */}
                        {/* product Information */}
                        <Row className="mb-3">
                          <h4 className="heading">Description Information</h4>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Validity"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Validity"
                                tabIndex={19}
                                name="validity"
                                defaultValue={data1.validity || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingSelect"
                              className="dropDown"
                              label="Applicable Law"
                            >
                              <Form.Select
                                aria-label="Floating label select example"
                                name="applicable_law"
                                tabIndex={21}
                                placeholder="Applicable Law"
                                defaultValue={data1.applicable_law || ""}
                                onChange={handleChangeInput}
                              >
                                <option value="">--None--</option>
                                <option value="English Law">English Law</option>
                                <option value="Singapore Law">
                                  Singapore Law
                                </option>
                                <option value="Indonesian Law">
                                  Indonesian Law
                                </option>
                              </Form.Select>
                            </FloatingLabel>
                          </Form.Group>

                          <Form.Group as={Col} md="12">
                            <FloatingLabel
                              controlId="floatingTextarea2"
                              label="Description"
                            >
                              <Form.Control
                                as="textarea"
                                placeholder="Description"
                                style={{ height: "200px" }}
                                name="description"
                                tabIndex={20}
                                defaultValue={data1.description || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Row>
                        <p className="edit-btn">
                          <input
                            type="submit"
                            className="account-save"
                            onClick={handleSaveEdit}
                            value="Save"
                            //disabled={isSubmitted}
                          />
                          <button
                            className="btn btn-primary"
                            onClick={handleCancelEdit}
                          >
                            Cancel
                          </button>
                        </p>
                      </div>
                    ) : (
                      <div className="tasks">
                        <div className="edit-delete">
                          <span id="edit" onClick={handleEditClick}>
                            Edit
                          </span>
                          <span>
                            <Link to={"/opportunity/create-view-list"}>
                              {" "}
                              Cancel
                            </Link>
                          </span>
                          <span>Submit for Approval</span>
                          <span>New Order</span>
                        </div>
                        {error && (
                          <p className="date-error">
                            <span>{error}</span>
                          </p>
                        )}
                        <div className="col-md-12" id="ax.account_details">
                          <h4 className="heading">Opportunity Information</h4>
                          <hr></hr>
                        </div>
                        <table class="table table-bordered account-table tables">
                          <tbody>
                            <tr>
                              <td id="td-right">
                                <span>Opportunity No</span>
                              </td>
                              <td>{data1.opportunity_no}</td>
                              <td id="td-right">
                                <span>Locked</span>
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  name="locked"
                                  checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                  onChange={handleCheckboxChange} // Handle the change event
                                  className="mx-3"
                                  disabled={isButtonEnabled}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td id="td-right">
                                <span>Exchange Rate </span>
                              </td>
                              <td>{data1.exchange_rate}</td>
                              <td id="td-right">
                                <span>Company</span>
                              </td>
                              <td>
                                <Link
                                  to={`/company/company-details/${data1.rwood_company_id}`}
                                >
                                  {data1.company}
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td id="td-right"></td>
                              <td></td>
                              <td id="td-right">
                                <span>opportunity Owner </span>
                              </td>
                              <td>{data1.opportunity_owner}</td>
                            </tr>{" "}
                            <tr>
                              <td id="td-right">
                                <span>opportunity record type</span>
                              </td>
                              <td>{data1.opportunity_record_type}</td>
                              <td id="td-right">
                                <span>opportunity name</span>
                              </td>
                              <td>{data1.opportunity_name}</td>
                            </tr>{" "}
                            <tr>
                              <td id="td-right">
                                <span>currency </span>
                              </td>
                              <td>{data1.currency}</td>
                              <td id="td-right">
                                <span>probability</span>
                              </td>
                              <td>{data1.probability}</td>
                            </tr>
                            <tr>
                              <td id="td-right">
                                <span>account name</span>
                              </td>
                              <td>
                                <Link to={"/accounts/" + data1.account_id}>
                                  {" "}
                                  {data1.account_name}{" "}
                                </Link>
                              </td>
                              <td id="td-right">
                                <span>supplier </span>
                              </td>
                              <td>
                                <Link to={"/accounts/" + data1.supplier_id}>
                                  {" "}
                                  {data1.supplier}{" "}
                                </Link>
                              </td>
                            </tr>{" "}
                            <tr>
                              <td id="td-right">
                                <span>trader name</span>
                              </td>
                              <td>
                                <Link
                                  to={
                                    "/user/user-details/" + data1.rwood_user_id
                                  }
                                >
                                  {" "}
                                  {data1.trader_name}{" "}
                                </Link>
                              </td>
                              <td id="td-right">
                                <span>no of trade confirmation</span>
                              </td>
                              <td>{data1.no_of_trade_confirmation}</td>
                            </tr>{" "}
                            <tr>
                              <td id="td-right">
                                <span>inquiry date </span>
                              </td>
                              <td>{data1.inquiry_date}</td>
                              <td id="td-right">
                                <span>close date</span>
                              </td>
                              <td>{data1.close_date}</td>
                            </tr>
                            <tr>
                              <td id="td-right">
                                {/* <span>no of trade confirmation</span> */}
                              </td>
                              <td>{/* {data1.no_of_trade_confirmation} */}</td>

                              <td id="td-right">
                                {/* <span>loss reason </span> */}
                              </td>
                              <td>{/* {data1.loss_reason} */}</td>
                            </tr>
                            <tr>
                              <td id="td-right">
                                <span>stage</span>
                              </td>
                              <td>{data1.stage}</td>
                              <td id="td-right">
                                {/* <span>primary campaign source</span> */}
                              </td>
                              <td>{/* {data1.primary_campaign_source} */}</td>
                            </tr>
                            <tr>
                              <td id="td-right">
                                <span>laycan year</span>
                              </td>
                              <td>{data1.laycan_year}</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="col-md-12" id="ax.account_details">
                          <h4 className="heading">Product Information</h4>
                          <hr></hr>
                        </div>
                        <table class="table table-bordered account-table tables">
                          <tbody>
                            <tr>
                              <td id="td-right">
                                <span>commodity </span>
                              </td>
                              <td>{data1.commodity}</td>
                              <td id="td-right">
                                <span>Amount</span>
                              </td>
                              <td>{renderShipmentQty(data1.amount)}</td>
                            </tr>
                            <tr>
                              <td id="td-right">
                                <span>contract quantity</span>
                              </td>
                              <td>{renderShipmentQty(data1.quantity_mt)}</td>
                              <td id="td-right">
                                {/* <span>contract quantity</span> */}
                              </td>
                              <td>
                                {/* {renderShipmentQty(data1.quantity_mt)} */}
                              </td>
                            </tr>
                            <tr>
                              <td id="td-right">
                                <span>shipment quantity</span>
                              </td>
                              <td>{renderShipmentQty(data1.quantity)}</td>
                              <td id="td-right">
                                <span className="a-a">
                                product description{" "}
                                                <Tooltip text={<span>Product Specification</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                              </td>
                              <td>{data1.product_description}</td>
                            </tr>
                          </tbody>
                        </table>

                        <div className="col-md-12" id="ax.account_details">
                          <h4 className="heading">Market Information</h4>
                          <hr></hr>
                        </div>
                        <table class="table table-bordered account-table tables">
                          <tbody>
                            <tr>
                              <td id="td-right">
                                <span>Origin</span>
                              </td>
                              <td>{data1.origin}</td>
                              <td id="td-right">
                                <span>destination</span>
                              </td>
                              <td>{data1.destination}</td>
                            </tr>
                          </tbody>
                        </table>

                        {/* <div className="col-md-12" id="ax.account_details">
                  <h4 className="heading">Price and Price Adjustment</h4>
                  <hr></hr>
                </div>
                <table class="table table-bordered account-table tables">
                  <tbody>
                    <tr>
                    <td id="td-right">
                        <span>Bonus penalty clause</span>
                        </td><td>
                        {data1.bonus_penalty_clause}
                      </td>

                      <td id="td-right">
                        <span>Rejections</span>
                        </td><td>
                        {data1.rejections}
                      </td>
                      </tr>
                      <tr>
                      <td id="td-right">
                        <span>Payment Terms</span>
                        </td><td>
                        {data1.payment_terms}
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table> */}

                        <div className="col-md-12" id="ax.account_details">
                          <h4 className="heading">Description Information</h4>
                          <hr></hr>
                        </div>
                        <table class="table table-bordered account-table tables">
                          <tbody>
                            <tr>
                              <td id="td-right">
                                <span>Validity</span>
                              </td>
                              <td>{data1.validity}</td>

                              <td id="td-right">
                                <span>Applicable Law</span>
                              </td>
                              <td>{data1.applicable_law}</td>
                            </tr>
                            <tr>
                              <td id="td-right">
                                <span>Description</span>
                              </td>
                              <td>{data1.description}</td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="col-md-12" id="ax.account_details">
                          <h4 className="heading">System Information</h4>
                          <hr></hr>
                        </div>
                        <table class="table table-bordered account-table tables">
                          <tbody>
                            <tr>
                              <td id="td-right">
                                <span>Created By</span>
                              </td>
                              <td>{data1.created_by}</td>
                              <td id="td-right">
                                <span>Last Modified By</span>
                              </td>
                              <td>{data1.modified_by}</td>
                            </tr>
                          </tbody>
                        </table>
                        {/* handleOppShipClick */}
                        <Row id="table-styles">
                          <div className="col-md-12" id="ax.account_details">
                            <h4 className="heading">
                              <span>Opportunity Shipment</span>
                              <span>
                                <button onClick={handleOppShipClick}>
                                  <i className="fe fe-download-cloud me-2"></i>
                                  New
                                </button>
                              </span>
                            </h4>
                            {/* <h4 className="heading"><span>Opportunity Shipment Information</span> <span  className="links"><FaPlus/> New</span></h4> */}
                            <p id="new-offer"></p>
                            <hr></hr>
                          </div>
                          <table id="shipment" className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Opportunity Shipment Name</th>
                                <th>Laycan</th>
                                <th>Quantity</th>
                                <th>Pricing</th>
                                <th>Quantity Info</th>
                                <th>Vessel Type</th>
                                <th>Shipping Term</th>
                              </tr>
                            </thead>
                            {shipments.length > 0 ? (
                              <tbody>
                                {shipments.map((y, i) => (
                                  <tr>
                                    <td>
                                      <span id="codes">
                                        <Link
                                          to={
                                            "/opportunity/oppshipment-detail/" +
                                            y.id
                                          }
                                        >
                                          {y.opp_ship_code}
                                        </Link>
                                      </span>
                                    </td>
                                    <td>{y.laycan}</td>
                                    <td>{renderShipmentQty(y.quantity)}</td>
                                    <td>{y.pricing}</td>
                                    <td>{y.quantity_info}</td>
                                    <td>{y.vessel_type}</td>
                                    <td>{y.shipping_term}</td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>

                                  <td>No data available</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="ax.account_details">
                            <h4 className="heading">
                              <span>Quotes Information</span>{" "}
                              <span className="links" onClick={addOffer}>
                                {" "}
                                Create Offer
                              </span>
                            </h4>
                            <hr></hr>
                            <p id="new-offer"></p>
                          </div>
                          <table id="quotes" className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Quote Number</th>
                                <th>Status</th>
                                <th>Quantity MT </th>
                                <th>Product Description</th>
                                <th>Quote Date</th>
                              </tr>
                            </thead>
                            {datas.length > 0 ? (
                              <tbody>
                                {datas.map((y, i) => (
                                  <tr>
                                    <td>
                                      <Link
                                        to={"/opportunity/quote-view/" + y.id}
                                      >
                                        <span id="codes">{y.quote_number}</span>
                                      </Link>
                                    </td>
                                    <td>{y.status}</td>
                                    <td>{renderShipmentQty(y.quantity_MT)}</td>
                                    <td>{y.product_description}</td>
                                    <td>{y.quote_date}</td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>No data available</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="ax.account_details">
                            <h4 className="heading">
                              <span>Trade Confirmation Forms</span>{" "}
                              <span className="links">New</span>
                            </h4>
                            <p id="new-offer"></p>
                            <hr></hr>
                          </div>
                          <table id="tcf" className="table table-bordered">
                            <thead>
                              <tr>
                                <th>TCF No</th>
                                <th>TCF Date</th>
                                <th>Buyer Contract </th>
                                <th>Order</th>
                                <th>TCF Status </th>
                                <th>Quote</th>
                                <th>Quote Line Item</th>
                              </tr>
                            </thead>
                            {tcf.length > 0 ? (
                              <tbody>
                                {tcf.map((y, i) => (
                                  <tr>
                                    <td>
                                      <span id="codes">
                                        <Link to={"/tcf/tcf-details/" + y.id}>
                                          {y.tcf_no}
                                        </Link>
                                      </span>
                                    </td>
                                    <td>{y.tcf_date}</td>
                                    <td>{y.buyer_contract}</td>

                                    <td>
                                      <Link
                                        to={
                                          "/order/view-order-details/" +
                                          y.rwood_order_id
                                        }
                                      >
                                        {y.order}
                                      </Link>
                                    </td>
                                    <td>{y.tcf_status}</td>
                                    <td>
                                      <Link
                                        to={`/opportunity/quote-view/${y.offer_id}`}
                                      >
                                        {y.quote}
                                      </Link>
                                    </td>
                                    <td></td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td>No data available</td>
                                  <td></td>
                                  <td></td> <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Orders</span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Contact Roles</span>
                              <span>New</span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Stage History</span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Competitors</span>
                              <span>New</span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Opportunity Field History</span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Notes & Attachments</span>
                              <span>
                                <i className=""></i>
                                New Note
                              </span>
                              <span>
                                <i className=""></i>
                                Attach File
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>
                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Notes</span>
                              <span>
                                <i className=""></i>
                                New Note
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Open Activities</span>
                              <span>
                                <i className=""></i>
                                New Task
                              </span>
                              <span>
                                <i className=""></i>
                                New Event
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>Activity History</span>
                              <span>
                                <i className=""></i>
                                Log a Call
                              </span>
                              <span>
                                <i className=""></i>
                                Mail merge
                              </span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="history" className="">
                            <tr>
                              <td>
                                <p class="no-records">No records to display</p>
                              </td>
                            </tr>
                          </table>
                        </Row>

                        <Row id="table-styles">
                          <div className="col-md-12" id="head">
                            <h4 className="heading">
                              <span>History Information</span>
                            </h4>
                            <hr></hr>
                          </div>
                          <table id="historys" className="">
                            <thead>
                              <tr>
                                <th>Field Name</th>
                                <th>New Value</th>
                                <th>Old Value</th>
                                <th>Changed By</th>
                                <th>Change Time</th>
                              </tr>
                            </thead>

                            {history.length > 0 ? (
                              <tbody>
                                {history.map((y, i) => (
                                  <tr>
                                    <td>{y.field_name}</td>
                                    <td>{y.new_value}</td>
                                    <td>{y.old_value}</td>
                                    <td>{y.changed_by}</td>
                                    <td>{y.change_time}</td>
                                  </tr>
                                ))}
                              </tbody>
                            ) : (
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td>No data available</td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </Row>

                        <p></p>
                        <p></p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {data1.opportunity_record_type === "Buyer - Long Term" ? (
                      <>
                        {isEditMode ? (
                          <div className="tasks oppEdit">
                            <div id="messageContainer"></div>
                            <p className="edit-btn">
                              <input
                                type="submit"
                                className="account-save"
                                onClick={handleSaveEdit}
                                value="Save"
                                //disabled={isSubmitted}
                              />
                              <button
                                className="btn btn-primary"
                                onClick={handleCancelEdit}
                              >
                                Cancel
                              </button>
                            </p>
                            {error && (
                              <p className="date-error">
                                <span>{error}</span>
                              </p>
                            )}
                            <Row className="mb-3">
                              <h4 className="heading">
                                Opportunity Information
                              </h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Company"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="company"
                                    tabIndex={1}
                                    placeholder="Company"
                                    defaultValue={data1.company || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select</option>
                                    {company.map((x) => {
                                      return (
                                        <option value={x.company_name}>
                                          {x.company_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <label>Locked</label>
                                <input
                                  type="checkbox"
                                  name="locked"
                                  tabIndex={12}
                                  checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                  onChange={handleCheckboxChange} // Handle the change event
                                  className="mx-3"
                                />
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Exchange rate"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Exchange rate"
                                    name="exchange_rate"
                                    tabIndex={2}
                                    defaultValue={data1.exchange_rate || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Opportunity record type"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Opportunity record type"
                                    name="opportunity_record_type"
                                    tabIndex={13}
                                    className="opportunity-record-type"
                                    defaultValue={data1.opportunity_record_type}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Currency"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="currency"
                                    tabIndex={3}
                                    placeholder="Currency"
                                    defaultValue={data1.currency}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">---None---</option>
                                    <option value="USD">USD</option>
                                    <option value="IDR">IDR</option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Opportunity Owner"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Opportunity Owner"
                                    name="opportunity_owner"
                                    tabIndex={14}
                                    defaultValue={data1.opportunity_owner}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Opportunity Name"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Opportunity Name"
                                    name="opportunity_name"
                                    tabIndex={4}
                                    className="error-validation"
                                    defaultValue={data1.opportunity_name}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              {/* <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="probability"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="probability"
                    name="probability"
                    defaultValue={data1.probability}
                            onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group> */}

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="No of trade confirmation"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="No of trade confirmation"
                                    name="no_of_trade_confirmation"
                                    tabIndex={15}
                                    defaultValue={
                                      data1.no_of_trade_confirmation
                                    }
                                    onChange={handleChangeInput}
                                  />
                                  {/* {errors.no_of_trade_confirmation && <span>Required</span>} */}
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="account name"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="account_name"
                                    tabIndex={5}
                                    placeholder="account name"
                                    className="error-validation"
                                    defaultValue={data1.account_name}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select</option>
                                    {accountName.map((x) => {
                                      return (
                                        <option value={x.account_name}>
                                          {x.account_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Supplier"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="supplier"
                                    placeholder="Supplier" disabled
                                    className="error-validation"
                                    tabIndex={6}
                                    defaultValue={data1.supplier}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select</option>
                                    {supplier.map((x) => {
                                      return (
                                        <option value={x.account_name}>
                                          {x.account_name}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              {/* <Form.Group as={Col} md="6">
              <FloatingLabel controlId="floatingSelect"
            className='dropDown' label="product status">
  <Form.Select aria-label="Floating label select example"
  name="product_status"
  placeholder="product status"

  defaultValue={data1.product_status}
                            onChange={handleChangeInput}
  >
<option value="">--None--</option><option value="Offered">Offered</option><option value="Approved">Approved</option><option value="Rejected">Rejected</option><option value="Sold">Sold</option>

  </Form.Select>
</FloatingLabel>
            </Form.Group>  */}

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Trader Name"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="trader_name"
                                    placeholder="Trader Name"
                                    tabIndex={7}
                                    className="error-validation"
                                    defaultValue={data1.trader_name || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select</option>
                                    {rwoodusers.map((x) => {
                                      return (
                                        <option value={x.rwood_username}>
                                          {x.rwood_username}
                                        </option>
                                      );
                                    })}
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              {/* <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="No of order"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="No of order"
                    name="no_of_order"
                    defaultValue={data1.no_of_order}
                            onChange={handleChangeInput}/>
                  </FloatingLabel>
                </Form.Group> */}
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Inquiry Date"
                                  className="mb-3 datepick"
                                >
                                  <DatePicker
                                    selected={inquiryDate} placeholderText="Select"
                                    selectsStart
                                    // minDate={startDate}
                                    // startDate={startDate}
                                    tabIndex={8}
                                    onChange={handleInquiryChange}
                                    dateFormat="dd/MM/yyyy"
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Close Date"
                                  className="mb-3 datepick error-validation"
                                >
                                  <DatePicker
                                    selectsStart
                                    // minDate={startDate}
                                    // startDate={startDate}
                                    selected={closeDate} placeholderText="Select"
                                    onChange={handleCloseChange}
                                    tabIndex={9}
                                    dateFormat="dd/MM/yyyy"
                                    onChangeRaw={handleEndDateInputChange} // Handle manual typing
                                  />
                                  {endDateError && (
                                    <Form.Text className="text-danger">
                                      {endDateError}
                                    </Form.Text>
                                  )}
                                </FloatingLabel>
                              </Form.Group>

                              {/* <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="No of trade confirmation"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="No of trade confirmation"
                    name="no_of_trade_confirmation"
                      defaultValue={data1.no_of_trade_confirmation}
                            onChange={handleChangeInput}
                    />
                  </FloatingLabel>
                </Form.Group> */}
                              {/* <Form.Group as={Col} md="6"></Form.Group>
                <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Loss reason "
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Loss reason"
                    name="loss_reason "
                     defaultValue={data1.loss_reason}
                            onChange={handleChangeInput}
                  />
                     </FloatingLabel>
                </Form.Group> */}
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Stage"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="stage"
                                    tabIndex={10}
                                    placeholder="Stage"
                                    className="error-validation"
                                    defaultValue={data1.stage}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">Select</option>
                                    <option value="Proposal">Proposal</option>
                                    <option value="Concluded">Concluded</option>
                                    <option value="Quoted">Quoted</option>
                                    <option value="Dropped">Dropped</option>
                                    <option value="Last">Last</option>
                                    <option value="Closed">Closed</option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              {/* <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Primary campaign source"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Primary campaign source"
                    name="primary_campaign_source"

   defaultValue={data1.primary_campaign_source}
                            onChange={handleChangeInput}/>
                     </FloatingLabel>
                </Form.Group> */}

                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Laycan Year"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="laycan_year"
                                    placeholder="Laycan Year"
                                    className="error-validation"
                                    tabIndex={11}
                                    defaultValue={data1.laycan_year}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">---None---</option>
                                    <option value="2021">2021</option>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2015">2015</option>
                                    <option value="2019">2019</option>
                                    <option value="2020">2020</option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <h4 className="heading">Product Information</h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Contract"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Contract"
                                    name="contract"
                                    tabIndex={16}
                                    defaultValue={data1.contract || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="contract quantity"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="contract quantity"
                                    name="quantity_mt"
                                    tabIndex={17}
                                    defaultValue={data1.quantity_mt || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Amount"
                          className="mb-3"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Amount"
                            name="amount"
                            defaultValue={data1.amount || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group> */}
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="shipment quantity "
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="shipment quantity"
                                    name="quantity"
                                    tabIndex={18}
                                    defaultValue={data1.quantity || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract"
                            placeholder="Contract"
                            defaultValue={data1.contract || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">Select</option>
                            {contract.map((x) => {
                              return (
                                <option value={x.contract_number}>
                                  {x.contract_number}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group> */}
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                className="textarea-half tooltips"
                              >
                                <FloatingLabel
                                  controlId="floatingTextarea2"
                                  label="Product description"
                                >
                                  <Form.Control
                                    as="textarea"
                                    placeholder="Product description"
                                    style={{ height: "200px" }}
                                    tabIndex={19}
                                    name="product_description"
                                    defaultValue={
                                      data1.product_description || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                                <Tooltip text={<span>Product Specification</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                            </Row>
                            <Row className="mb-3">
                              <h4 className="heading">Market Information</h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Origin"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Origin"
                                    tabIndex={20}
                                    name="origin"
                                    defaultValue={data1.origin || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Commodity"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="commodity"
                                    placeholder="Commodity"
                                    tabIndex={22}
                                    defaultValue={data1.commodity || ""}
                                    onChange={handleChangeInput}
                                  >
                                    <option>---None---</option>
                                    <option value='Indonesian Steam Coal in Bulk ("Coal")'>
                                      Indonesian Steam Coal in Bulk ("Coal")
                                    </option>
                                    <option value="US Coal">US Coal</option>
                                    <option value="Russia Coal">
                                      Russia Coal
                                    </option>
                                    <option value="SA Coal">SA Coal</option>
                                    <option value="Clinker in Bulk">
                                      Clinker in Bulk
                                    </option>
                                    <option value="Australian Steam Coal">
                                      Australian Steam Coal
                                    </option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingTextarea2"
                                  label="Destination"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Destination"
                                    tabIndex={21}
                                    style={{ height: "200px" }}
                                    name="destination"
                                    defaultValue={data1.destination || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                            </Row>
                            {/* <Row className="mb-3">
                      <h4 className="heading">Price and Price Adjustment</h4>

                      <Form.Group as={Col} md="12">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Bonus penalty clause"
                          className="mb-3"
                        >
                          <Form.Control
                            as="textarea"
                            placeholder="Bonus penalty clause"
                            name="bonus_penalty_clause"
                            defaultValue={data1.bonus_penalty_clause || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="12">
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Rejections"
                          className="mb-3"
                        >
                          <Form.Control
                            as="textarea"
                            placeholder="Rejections"
                            name="rejections"
                            defaultValue={data1.rejections || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                      <Form.Group as={Col} md="12">
                        <FloatingLabel
                          controlId="floatingTextarea2"
                          label="Payment terms"
                        >
                          <Form.Control
                            as="textarea"
                            placeholder="Payment terms"
                            style={{ height: "200px" }}
                            name="payment_terms"
                            defaultValue={data1.payment_terms || ""}
                            onChange={handleChangeInput}
                          />
                        </FloatingLabel>
                      </Form.Group>
                    </Row> */}
                            {/* product Information */}
                            <Row className="mb-3">
                              <h4 className="heading">
                                Description Information
                              </h4>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Validity"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Validity"
                                    name="validity"
                                    tabIndex={23}
                                    defaultValue={data1.validity || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingSelect"
                                  className="dropDown"
                                  label="Applicable Law"
                                >
                                  <Form.Select
                                    aria-label="Floating label select example"
                                    name="applicable_law"
                                    placeholder="Applicable Law"
                                    defaultValue={data1.applicable_law || ""}
                                    tabIndex={24}
                                    onChange={handleChangeInput}
                                  >
                                    <option value="">--None--</option>
                                    <option value="English Law">
                                      English Law
                                    </option>
                                    <option value="Singapore Law">
                                      Singapore Law
                                    </option>
                                    <option value="Indonesian Law">
                                      Indonesian Law
                                    </option>
                                  </Form.Select>
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                              <Form.Group
                                as={Col}
                                md="6"
                                className="textarea-half"
                              >
                                <FloatingLabel
                                  controlId="floatingTextarea2"
                                  label="Description"
                                >
                                  <Form.Control
                                    as="textarea"
                                    tabIndex={25}
                                    placeholder="Description"
                                    style={{ height: "200px" }}
                                    name="description"
                                    defaultValue={data1.description || ""}
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group as={Col} md="6"></Form.Group>
                            </Row>
                            <p className="edit-btn">
                              <input
                                type="submit"
                                className="account-save"
                                onClick={handleSaveEdit}
                                value="Save"
                                //disabled={isSubmitted}
                              />
                              <button
                                className="btn btn-primary"
                                onClick={handleCancelEdit}
                              >
                                Cancel
                              </button>
                            </p>
                          </div>
                        ) : (
                          <div className="tasks" id="view-page">
                            <div className="edit-delete">
                              <span id="edit" onClick={handleEditClick}>
                                Edit
                              </span>
                              <span>
                                <Link to={"/opportunity/create-view-list"}>
                                  {" "}
                                  Cancel
                                </Link>
                              </span>
                              <span>Submit for Approval</span>
                              <span>New Order</span>
                            </div>
                            {error && (
                              <p className="date-error">
                                <span>{error}</span>
                              </p>
                            )}
                            <div className="col-md-12" id="ax.account_details">
                              <h4 className="heading">
                                Opportunity Information
                              </h4>
                              <hr></hr>
                            </div>
                            <table class="table table-bordered account-table tables">
                              <tbody>
                                <tr>
                                  <td id="td-right">
                                    <span>Opportunity No</span>
                                  </td>
                                  <td>{data1.opportunity_no}</td>
                                  <td id="td-right">
                                    <span>Locked</span>
                                  </td>
                                  <td>
                                    <input
                                      type="checkbox"
                                      name="locked"
                                      checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                      onChange={handleCheckboxChange} // Handle the change event
                                      className="mx-3"
                                      disabled={isButtonEnabled}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>Company</span>
                                  </td>
                                  <td>
                                    <Link
                                      to={`/company/company-details/${data1.rwood_company_id}`}
                                    >
                                      {data1.company}
                                    </Link>
                                  </td>
                                  <td id="td-right">
                                    <span>Opportunity Record Type</span>
                                  </td>
                                  <td>{data1.opportunity_record_type}</td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>Exchange Rate </span>
                                  </td>
                                  <td>{data1.exchange_rate}</td>
                                  <td id="td-right">
                                    <span>opportunity Owner </span>
                                  </td>
                                  <td>{data1.opportunity_owner}</td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>currency </span>
                                  </td>
                                  <td>{data1.currency}</td>
                                  <td id="td-right">
                                    <span>no of trade confirmation</span>
                                  </td>
                                  <td>{data1.no_of_trade_confirmation}</td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>opportunity name</span>
                                  </td>
                                  <td>{data1.opportunity_name}</td>
                                  <td id="td-right">
                                    <span></span>
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>account name</span>
                                  </td>
                                  <td>
                                    <Link to={"/accounts/" + data1.account_id}>
                                      {" "}
                                      {data1.account_name}{" "}
                                    </Link>
                                  </td>
                                  <td id="td-right">
                                    {/* <span>Product Status</span> */}
                                  </td>
                                  <td>{/* {data1.product_status} */}</td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>supplier </span>
                                  </td>
                                  <td>
                                    <Link to={"/accounts/" + data1.supplier_id}>
                                      {" "}
                                      {data1.supplier}{" "}
                                    </Link>
                                  </td>
                                  <td id="td-right">
                                    {/* <span>no of order</span> */}
                                  </td>
                                  <td>{/* {data1.no_of_order} */}</td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>trader name</span>
                                  </td>
                                  <td>
                                    <Link
                                      to={
                                        "/user/user-details/" +
                                        data1.rwood_users_id
                                      }
                                    >
                                      {" "}
                                      {data1.trader_name}{" "}
                                    </Link>
                                  </td>

                                  <td id="td-right">
                                    {/* <span>no of trade_confirmation</span> */}
                                  </td>
                                  <td>
                                    {/* {data1.no_of_trade_confirmation} */}
                                  </td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>inquiry date </span>
                                  </td>
                                  <td>{data1.inquiry_date}</td>
                                  <td id="td-right">
                                    {/* <span>Loss Reason	 */}
                                    {/* </span> */}
                                  </td>
                                  <td>{/* {data1.loss_reason} */}</td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>close date</span>
                                  </td>
                                  <td>{data1.close_date}</td>
                                  <td id="td-right">
                                    {/* <span>Loss Reason Remark</span> */}
                                  </td>
                                  <td>{/* {data1.loss_reason_remark} */}</td>
                                </tr>

                                <tr>
                                  <td id="td-right">
                                    <span>stage</span>
                                  </td>
                                  <td>{data1.stage}</td>

                                  <td id="td-right"></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    {/* <span>primary campaign source</span> */}
                                  </td>
                                  <td>
                                    {/* {data1.primary_campaign_source} */}
                                  </td>

                                  <td id="td-right"></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>No of Opp Shipments</span>
                                  </td>
                                  <td>{data1.no_of_opp_shipments}</td>
                                  <td id="td-right"></td>
                                  <td></td>
                                </tr>

                                <tr>
                                  <td id="td-right">
                                    <span>laycan year</span>
                                  </td>
                                  <td>{data1.laycan_year}</td>
                                  <td id="td-right"></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="col-md-12" id="ax.account_details">
                              <h4 className="heading">Product Information</h4>
                              <hr></hr>
                            </div>
                            <table class="table table-bordered account-table tables">
                              <tbody>
                                <tr>
                                  <td id="td-right">
                                    <span>Contract </span>
                                  </td>
                                  <td>
                                    <Link
                                      to={
                                        "/contract/contract-details/" +
                                        data1.contract_id
                                      }
                                    >
                                      {" "}
                                      {data1.contract}{" "}
                                    </Link>
                                  </td>
                                  <td id="td-right"></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>contract quantity</span>
                                  </td>
                                  <td>
                                    {renderShipmentQty(data1.quantity_mt)}
                                  </td>
                                  <td id="td-right"></td>
                                  <td></td>
                                </tr>

                                <tr>
                                  <td id="td-right">
                                    <span>shipment quantity</span>
                                  </td>
                                  <td>{renderShipmentQty(data1.quantity)}</td>
                                  <td id="td-right"></td>
                                  <td></td>
                                </tr>

                                <tr>
                                  <td id="td-right">
                                  <span className="a-a">
                                product description{" "}
                                                <Tooltip text={<span>Product Specification</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                  </td>
                                  <td>{data1.product_description}</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>

                            <div className="col-md-12" id="ax.account_details">
                              <h4 className="heading">Market Information</h4>
                              <hr></hr>
                            </div>
                            <table class="table table-bordered account-table tables">
                              <tbody>
                                <tr>
                                  <td id="td-right">
                                    <span>Origin</span>
                                  </td>
                                  <td>{data1.origin}</td>

                                  <td id="td-right">
                                    <span>commodity</span>
                                  </td>
                                  <td>{data1.commodity}</td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>destination</span>
                                  </td>
                                  <td>{data1.destination}</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>

                            {/* <div className="col-md-12" id="ax.account_details">
                      <h4 className="heading">Price and Price Adjustment</h4>
                      <hr></hr>
                    </div>
                    <table class="table table-bordered account-table tables">
                      <tbody>
                        <tr>
                        <td id="td-right">
                            <span>Bonus penalty clause</span>
                            </td><td>
                            {data1.bonus_penalty_clause}
                          </td>

                          <td id="td-right">
                            <span>Rejections</span>
                            </td><td>
                            {data1.rejections}
                          </td>

                        </tr>
                        <tr>
                        <td id="td-right">
                            <span>Payment Terms</span>
                            </td><td>
                            {data1.payment_terms}
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table> */}

                            <div className="col-md-12" id="ax.account_details">
                              <h4 className="heading">
                                Description Information
                              </h4>
                              <hr></hr>
                            </div>
                            <table class="table table-bordered account-table tables">
                              <tbody>
                                <tr>
                                  <td id="td-right">
                                    <span>Validity</span>
                                  </td>
                                  <td>{data1.validity}</td>

                                  <td id="td-right"></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>Applicable Law</span>
                                  </td>
                                  <td>{data1.applicable_law}</td>

                                  <td id="td-right"></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td id="td-right">
                                    <span>Description</span>
                                  </td>
                                  <td>{data1.description}</td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                            <div className="col-md-12" id="ax.account_details">
                              <h4 className="heading">System Information</h4>
                              <hr></hr>
                            </div>
                            <table class="table table-bordered account-table tables">
                              <tbody>
                                <tr>
                                  <td id="td-right">
                                    <span>Created By</span>
                                  </td>
                                  <td>{data1.created_by}</td>
                                  <td id="td-right">
                                    <span>Last Modified By</span>
                                  </td>
                                  <td>{data1.modified_by}</td>
                                </tr>
                              </tbody>
                            </table>

                            <Row id="table-styles">
                              <div
                                className="col-md-12"
                                id="ax.account_details"
                              >
                                <h4 className="heading">
                                  <span>Opportunity Shipment</span>
                                  <span>
                                    <Link
                                      to={"/opp/opportunity-shipment/" + id}
                                    >
                                      <i className="fe fe-download-cloud me-2"></i>
                                      New
                                    </Link>
                                  </span>
                                </h4>
                                {/* <h4 className="heading"><span>Opportunity Shipment Information</span> <span  className="links"><FaPlus/> New</span></h4> */}
                                <p id="new-offer"></p>
                                <hr></hr>
                              </div>
                              <table
                                id="shipment"
                                className="table table-bordered"
                              >
                                <thead>
                                  <tr>
                                    <th>Opportunity Shipment Name</th>
                                    <th>Laycan</th>
                                    <th>Quantity</th>
                                    <th>Pricing</th>
                                    <th>Quantity Info</th>
                                    <th>Vessel Type</th>
                                    <th>Shipping Term</th>
                                  </tr>
                                </thead>
                                {shipments.length > 0 ? (
                                  <tbody>
                                    {shipments.map((y, i) => (
                                      <tr>
                                        <td>
                                          <span id="codes">
                                            <Link
                                              to={
                                                "/opportunity/oppshipment-detail/" +
                                                y.id
                                              }
                                            >
                                              {y.opp_ship_code}
                                            </Link>
                                          </span>
                                        </td>
                                        <td>{y.laycan}</td>
                                        <td>{renderShipmentQty(y.quantity)}</td>
                                        <td>{y.pricing}</td>
                                        <td>{y.quantity_info}</td>
                                        <td>{y.vessel_type}</td>
                                        <td>{y.shipping_term}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>

                                      <td>No data available</td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                            </Row>

                            <Row id="table-styles">
                              <div
                                className="col-md-12"
                                id="ax.account_details"
                              >
                                <h4 className="heading">
                                  <span>Quotes Information</span>{" "}
                                  <span className="links" onClick={addOffer}>
                                    {" "}
                                    Create Offer
                                  </span>
                                </h4>
                                <hr></hr>
                                <p id="new-offer"></p>
                              </div>
                              <table
                                id="quotes"
                                className="table table-bordered"
                              >
                                <thead>
                                  <tr>
                                    <th>Quote Number</th>
                                    <th>Status</th>
                                    <th>Quantity MT </th>
                                    <th>Product Description</th>
                                    <th>Quote Date</th>
                                  </tr>
                                </thead>
                                {datas.length > 0 ? (
                                  <tbody>
                                    {datas.map((y, i) => (
                                      <tr>
                                        <td>
                                          <Link
                                            to={
                                              "/opportunity/quote-view/" + y.id
                                            }
                                          >
                                            <span id="codes">
                                              {y.quote_number}
                                            </span>
                                          </Link>
                                        </td>
                                        <td>{y.status}</td>
                                        <td>
                                          {renderShipmentQty(y.quantity_MT)}
                                        </td>
                                        <td>{y.product_description}</td>
                                        <td>{y.quote_date}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>No data available</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                            </Row>

                            <Row id="table-styles">
                              <div
                                className="col-md-12"
                                id="ax.account_details"
                              >
                                <h4 className="heading">
                                  <span>Trade Confirmation Forms</span>{" "}
                                  <span className="links">New</span>
                                </h4>
                                <p id="new-offer"></p>
                                <hr></hr>
                              </div>
                              <table id="tcf" className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>TCF No</th>
                                    <th>TCF Date</th>
                                    <th>Buyer Contract </th>
                                    <th>Order</th>
                                    <th>TCF Status </th>
                                    <th>Quote</th>
                                    <th>Quote Line Item</th>
                                  </tr>
                                </thead>
                                {tcf.length > 0 ? (
                                  <tbody>
                                    {tcf.map((y, i) => (
                                      <tr>
                                        <td>
                                          <span id="codes">
                                            <Link
                                              to={"/tcf/tcf-details/" + y.id}
                                            >
                                              {y.tcf_no}
                                            </Link>
                                          </span>
                                        </td>
                                        <td>{y.tcf_date}</td>
                                        <td>{y.buyer_contract}</td>

                                        <td>
                                          <Link
                                            to={
                                              "/order/view-order-details/" +
                                              y.rwood_order_id
                                            }
                                          >
                                            {y.order}
                                          </Link>
                                        </td>
                                        <td>{y.tcf_status}</td>
                                        <td>
                                          <Link
                                            to={`/opportunity/quote-view/${y.offer_id}`}
                                          >
                                            {y.quote}
                                          </Link>
                                        </td>
                                        <td></td>
                                      </tr>
                                    ))}
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No data available</td>
                                      <td></td>
                                      <td></td> <td></td>
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                            </Row>

                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Orders</span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>

                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Contact Roles</span>
                                  <span>New</span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>

                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Stage History</span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>

                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Competitors</span>
                                  <span>New</span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>

                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Opportunity Field History</span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>

                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Notes & Attachments</span>
                                  <span>
                                    <i className=""></i>
                                    New Note
                                  </span>
                                  <span>
                                    <i className=""></i>
                                    Attach File
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>
                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Notes</span>
                                  <span>
                                    <i className=""></i>
                                    New Note
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>

                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Open Activities</span>
                                  <span>
                                    <i className=""></i>
                                    New Task
                                  </span>
                                  <span>
                                    <i className=""></i>
                                    New Event
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>

                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>Activity History</span>
                                  <span>
                                    <i className=""></i>
                                    Log a Call
                                  </span>
                                  <span>
                                    <i className=""></i>
                                    Mail merge
                                  </span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="history" className="">
                                <tr>
                                  <td>
                                    <p class="no-records">
                                      No records to display
                                    </p>
                                  </td>
                                </tr>
                              </table>
                            </Row>

                            <Row id="table-styles">
                              <div className="col-md-12" id="head">
                                <h4 className="heading">
                                  <span>History Information</span>
                                </h4>
                                <hr></hr>
                              </div>
                              <table id="historys" className="">
                                <thead>
                                  <tr>
                                    <th>Field Name</th>
                                    <th>New Value</th>
                                    <th>Old Value</th>
                                    <th>Changed By</th>
                                    <th>Change Time</th>
                                  </tr>
                                </thead>

                                {history.length > 0 ? (
                                  <tbody>
                                    {history.map((y, i) => (
                                      <tr>
                                        <td>{y.field_name}</td>
                                        <td>{y.new_value}</td>
                                        <td>{y.old_value}</td>
                                        <td>{y.changed_by}</td>
                                        <td>{y.change_time}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>No data available</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                )}
                              </table>
                            </Row>

                            <p></p>
                            <p></p>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {data1.opportunity_record_type === "Buyer - Spot" ? (
                          <>
                            {isEditMode ? (
                              <div className="tasks oppEdit">
                                <div id="messageContainer"></div>
                                <p className="edit-btn">
                                  <input
                                    type="submit"
                                    className="account-save"
                                    onClick={handleSaveEdit}
                                    value="Save"
                                    //disabled={isSubmitted}
                                  />
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleCancelEdit}
                                  >
                                    Cancel
                                  </button>
                                </p>
                                {error && (
                                  <p className="date-error">
                                    <span>{error}</span>
                                  </p>
                                )}
                                <Row className="mb-3">
                                  <h4 className="heading">
                                    Opportunity Information
                                  </h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Company"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="company"
                                        tabIndex={1}
                                        placeholder="Company"
                                        defaultValue={data1.company || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select</option>
                                        {company.map((x) => {
                                          return (
                                            <option value={x.company_name}>
                                              {x.company_name}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <label>Locked</label>
                                    <input
                                      type="checkbox"
                                      name="locked"
                                      tabIndex={12}
                                      checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                      onChange={handleCheckboxChange} // Handle the change event
                                      className="mx-3"
                                    />
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Exchange rate"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Exchange rate"
                                        name="exchange_rate"
                                        tabIndex={2}
                                        defaultValue={data1.exchange_rate || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Opportunity record type"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Opportunity record type"
                                        name="opportunity_record_type"
                                        tabIndex={13}
                                        className="opportunity-record-type"
                                        defaultValue={
                                          data1.opportunity_record_type
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Currency"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="currency"
                                        tabIndex={3}
                                        placeholder="Currency"
                                        defaultValue={data1.currency}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">---None---</option>
                                        <option value="USD">USD</option>
                                        <option value="IDR">IDR</option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Opportunity Owner"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Opportunity Owner"
                                        name="opportunity_owner"
                                        tabIndex={14}
                                        defaultValue={data1.opportunity_owner}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Opportunity Name"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Opportunity Name"
                                        tabIndex={4}
                                        name="opportunity_name"
                                        className="error-validation"
                                        defaultValue={data1.opportunity_name}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="No of trade confirmation"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="No of trade confirmation"
                                        tabIndex={15}
                                        name="no_of_trade_confirmation"
                                        defaultValue={
                                          data1.no_of_trade_confirmation
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="account name"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="account_name"
                                        placeholder="account name"
                                        tabIndex={5}
                                        className="error-validation"
                                        defaultValue={data1.account_name}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select</option>
                                        {accountName.map((x) => {
                                          return (
                                            <option value={x.account_name}>
                                              {x.account_name}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>

                                  <Form.Group as={Col} md="6"></Form.Group>

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Supplier"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="supplier"
                                        placeholder="Supplier" disabled
                                        tabIndex={6}
                                        className="error-validation"
                                        defaultValue={data1.supplier}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select</option>
                                        {supplier.map((x) => {
                                          return (
                                            <option value={x.account_name}>
                                              {x.account_name}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Trader Name"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="trader_name"
                                        placeholder="Trader Name"
                                        tabIndex={7}
                                        className="error-validation"
                                        defaultValue={data1.trader_name || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select</option>
                                        {rwoodusers.map((x) => {
                                          return (
                                            <option value={x.rwood_username}>
                                              {x.rwood_username}
                                            </option>
                                          );
                                        })}
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Inquiry Date"
                                      className="mb-3 datepick"
                                    >
                                      <DatePicker
                                        selectsStart
                                        // minDate={startDate}
                                        // startDate={startDate}
                                        selected={inquiryDate} placeholderText="Select"
                                        tabIndex={8}
                                        onChange={handleInquiryChange}
                                        dateFormat="dd/MM/yyyy"
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Close Date"
                                      className="mb-3 datepick error-validation"
                                    >
                                      <DatePicker
                                        selectsStart
                                        // minDate={startDate}
                                        // startDate={startDate}
                                        selected={closeDate} placeholderText="Select"
                                        tabIndex={9}
                                        onChange={handleCloseChange}
                                        dateFormat="dd/MM/yyyy"
                                        onChangeRaw={handleEndDateInputChange} // Handle manual typing
                                      />
                                      {endDateError && (
                                        <Form.Text className="text-danger">
                                          {endDateError}
                                        </Form.Text>
                                      )}
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Stage"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="stage"
                                        placeholder="Stage"
                                        className="error-validation"
                                        defaultValue={data1.stage}
                                        tabIndex={10}
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">Select</option>
                                        <option value="Proposal">
                                          Proposal
                                        </option>
                                        <option value="Concluded">
                                          Concluded
                                        </option>
                                        <option value="Quoted">Quoted</option>
                                        <option value="Dropped">Dropped</option>
                                        <option value="Last">Last</option>
                                        <option value="Closed">Closed</option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  {/* <Form.Group as={Col} md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Primary campaign source"
                    className="mb-3"
                  >

                    <Form.Control type="text"
                    placeholder="Primary campaign source"
                    name="primary_campaign_source"

   defaultValue={data1.primary_campaign_source}
                            onChange={handleChangeInput}/>
                     </FloatingLabel>
                </Form.Group> */}

                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Laycan Year"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="laycan_year"
                                        placeholder="Laycan Year"
                                        className="error-validation"
                                        defaultValue={data1.laycan_year}
                                        onChange={handleChangeInput}
                                        tabIndex={11}
                                      >
                                        <option value="">---None---</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2026">2026</option>
                                        <option value="2015">2015</option>
                                        <option value="2019">2019</option>
                                        <option value="2020">2020</option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                  <h4 className="heading">
                                    Product Information
                                  </h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="contract quantity"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="contract quantity"
                                        name="quantity_mt"
                                        tabIndex={16}
                                        defaultValue={data1.quantity_mt || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    {" "}
                                  </Form.Group>

                                  {/* <Form.Group as={Col} md="6">
                        <FloatingLabel
                          controlId="floatingSelect"
                          className="dropDown"
                          label="Contract"
                        >
                          <Form.Select
                            aria-label="Floating label select example"
                            name="contract"
                            placeholder="Contract"
                            defaultValue={data1.contract || ""}
                            onChange={handleChangeInput}
                          >
                            <option value="">Select</option>
                            {contract.map((x) => {
                              return (
                                <option value={x.contract_number}>
                                  {x.contract_number}
                                </option>
                              );
                            })}
                          </Form.Select>
                        </FloatingLabel>
                      </Form.Group> */}
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    className="textarea-half tooltips"
                                  >
                                    <FloatingLabel
                                      controlId="floatingTextarea2"
                                      label="Product description"
                                    >
                                      <Form.Control
                                        as="textarea"
                                        placeholder="Product description"
                                        tabIndex={17}
                                        style={{ height: "200px" }}
                                        name="product_description"
                                        
                                        defaultValue={
                                          data1.product_description || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                    <Tooltip text={<span>Product Specification</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                </Row>
                                <Row className="mb-3">
                                  <h4 className="heading">
                                    Market Information
                                  </h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Origin"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        tabIndex={18}
                                        placeholder="Origin"
                                        name="origin"
                                        defaultValue={data1.origin || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Commodity"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="commodity"
                                        tabIndex={20}
                                        placeholder="Commodity"
                                        defaultValue={data1.commodity || ""}
                                        onChange={handleChangeInput}
                                      >
                                        <option>---None---</option>
                                        <option value='Indonesian Steam Coal in Bulk ("Coal")'>
                                          Indonesian Steam Coal in Bulk ("Coal")
                                        </option>
                                        <option value="US Coal">US Coal</option>
                                        <option value="Russia Coal">
                                          Russia Coal
                                        </option>
                                        <option value="SA Coal">SA Coal</option>
                                        <option value="Clinker in Bulk">
                                          Clinker in Bulk
                                        </option>
                                        <option value="Australian Steam Coal">
                                          Australian Steam Coal
                                        </option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingTextarea2"
                                      label="Destination"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        tabIndex={19}
                                        placeholder="Destination"
                                        style={{ height: "200px" }}
                                        name="destination"
                                        defaultValue={data1.destination || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                </Row>
                                {/* <Row className="mb-3">
                          <h4 className="heading">
                            Price and Price Adjustment
                          </h4>

                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Bonus penalty clause"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Bonus penalty clause"
                                name="bonus_penalty_clause"
                                defaultValue={data1.bonus_penalty_clause || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="6">
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Rejections"
                              className="mb-3"
                            >
                              <Form.Control
                                type="text"
                                placeholder="Rejections"
                                name="rejections"
                                defaultValue={data1.rejections || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group as={Col} md="12">
                            <FloatingLabel
                              controlId="floatingTextarea2"
                              label="Payment terms"
                            >
                              <Form.Control
                                as="textarea"
                                placeholder="Payment terms"
                                style={{ height: "200px" }}
                                name="payment_terms"
                                defaultValue={data1.payment_terms || ""}
                                onChange={handleChangeInput}
                              />
                            </FloatingLabel>
                          </Form.Group>
                        </Row> */}
                                {/* product Information */}
                                <Row className="mb-3">
                                  <h4 className="heading">
                                    System Information
                                  </h4>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Validity"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        tabIndex={21}
                                        placeholder="Validity"
                                        name="validity"
                                        defaultValue={data1.validity || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingSelect"
                                      className="dropDown"
                                      label="Applicable Law"
                                    >
                                      <Form.Select
                                        aria-label="Floating label select example"
                                        name="applicable_law"
                                        placeholder="Applicable Law"
                                        tabIndex={22}
                                        defaultValue={
                                          data1.applicable_law || ""
                                        }
                                        onChange={handleChangeInput}
                                      >
                                        <option value="">--None--</option>
                                        <option value="English Law">
                                          English Law
                                        </option>
                                        <option value="Singapore Law">
                                          Singapore Law
                                        </option>
                                        <option value="Indonesian Law">
                                          Indonesian Law
                                        </option>
                                      </Form.Select>
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                  <Form.Group
                                    as={Col}
                                    md="6"
                                    className="textarea-half"
                                  >
                                    <FloatingLabel
                                      controlId="floatingTextarea2"
                                      label="Description"
                                    >
                                      <Form.Control
                                        as="textarea"
                                        placeholder="Description"
                                        tabIndex={23}
                                        style={{ height: "200px" }}
                                        name="description"
                                        defaultValue={data1.description || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>
                                  <Form.Group as={Col} md="6"></Form.Group>
                                </Row>
                                <p className="edit-btn">
                                  <input
                                    type="submit"
                                    className="account-save"
                                    onClick={handleSaveEdit}
                                    value="Save"
                                    //disabled={isSubmitted}
                                  />
                                  <button
                                    className="btn btn-primary"
                                    onClick={handleCancelEdit}
                                  >
                                    Cancel
                                  </button>
                                </p>
                              </div>
                            ) : (
                              <div className="tasks">
                                <div className="edit-delete">
                                  <span id="edit" onClick={handleEditClick}>
                                    Edit
                                  </span>
                                  <span>
                                    <Link to={"/opportunity/create-view-list"}>
                                      {" "}
                                      Cancel
                                    </Link>
                                  </span>
                                  <span>Submit for Approval</span>
                                  <span>New Order</span>
                                </div>
                                {error && (
                                  <p className="date-error">
                                    <span>{error}</span>
                                  </p>
                                )}
                                <div
                                  className="col-md-12"
                                  id="ax.account_details"
                                >
                                  <h4 className="heading">
                                    Opportunity Information
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table class="table table-bordered account-table tables">
                                  <tbody>
                                    <tr>
                                      <td id="td-right">
                                        <span>Opportunity No</span>
                                      </td>
                                      <td>{data1.opportunity_no}</td>
                                      <td id="td-right">
                                        <span>Locked</span>
                                      </td>
                                      <td>
                                        <input
                                          type="checkbox"
                                          name="locked"
                                          checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                          onChange={handleCheckboxChange} // Handle the change event
                                          className="mx-3"
                                          disabled={isButtonEnabled}
                                        />
                                      </td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>Company</span>
                                      </td>
                                      <td>
                                        <Link
                                          to={`/company/company-details/${data1.rwood_company_id}`}
                                        >
                                          {data1.company}
                                        </Link>
                                      </td>
                                      <td id="td-right">
                                        <span>Opportunity Record Type</span>
                                      </td>
                                      <td>{data1.opportunity_record_type}</td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>Exchange Rate </span>
                                      </td>
                                      <td>{data1.exchange_rate}</td>
                                      <td id="td-right">
                                        <span>opportunity Owner </span>
                                      </td>
                                      <td>{data1.opportunity_owner}</td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>currency </span>
                                      </td>
                                      <td>{data1.currency}</td>
                                      <td id="td-right">
                                        <span>no of trade confirmation</span>
                                      </td>
                                      <td>{data1.no_of_trade_confirmation}</td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>opportunity name</span>
                                      </td>
                                      <td>{data1.opportunity_name}</td>
                                      <td id="td-right">
                                        <span></span>
                                      </td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>account name</span>
                                      </td>
                                      <td>
                                        <Link
                                          to={"/accounts/" + data1.account_id}
                                        >
                                          {" "}
                                          {data1.account_name}{" "}
                                        </Link>
                                      </td>
                                      <td id="td-right">
                                        {/* <span>Product Status</span> */}
                                      </td>
                                      <td>{/* {data1.product_status} */}</td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>supplier </span>
                                      </td>
                                      <td>
                                        <Link
                                          to={"/accounts/" + data1.supplier_id}
                                        >
                                          {" "}
                                          {data1.supplier}{" "}
                                        </Link>
                                      </td>
                                      <td id="td-right">
                                        {/* <span>no of order</span> */}
                                      </td>
                                      <td>{/* {data1.no_of_order} */}</td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>trader name</span>
                                      </td>
                                      <td>
                                        <Link
                                          to={
                                            "/user/user-details/" +
                                            data1.rwood_users_id
                                          }
                                        >
                                          {" "}
                                          {data1.trader_name}{" "}
                                        </Link>
                                      </td>

                                      <td id="td-right">
                                        {/* <span>no of trade_confirmation</span> */}
                                      </td>
                                      <td>
                                        {/* {data1.no_of_trade_confirmation} */}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>inquiry date </span>
                                      </td>
                                      <td>{data1.inquiry_date}</td>
                                      <td id="td-right">
                                        {/* <span>Loss Reason	 */}
                                        {/* </span> */}
                                      </td>
                                      <td>{/* {data1.loss_reason} */}</td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>close date</span>
                                      </td>
                                      <td>{data1.close_date}</td>
                                      <td id="td-right">
                                        {/* <span>Loss Reason Remark</span> */}
                                      </td>
                                      <td>
                                        {/* {data1.loss_reason_remark} */}
                                      </td>
                                    </tr>

                                    <tr>
                                      <td id="td-right">
                                        <span>stage</span>
                                      </td>
                                      <td>{data1.stage}</td>

                                      <td id="td-right"></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        {/* <span>primary campaign source</span> */}
                                      </td>
                                      <td>
                                        {/* {data1.primary_campaign_source} */}
                                      </td>

                                      <td id="td-right"></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>No of Opp Shipments</span>
                                      </td>
                                      <td>{data1.no_of_opp_shipments}</td>
                                      <td id="td-right"></td>
                                      <td></td>
                                    </tr>

                                    <tr>
                                      <td id="td-right">
                                        <span>laycan year</span>
                                      </td>
                                      <td>{data1.laycan_year}</td>
                                      <td id="td-right"></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  className="col-md-12"
                                  id="ax.account_details"
                                >
                                  <h4 className="heading">
                                    Product Information
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table class="table table-bordered account-table tables">
                                  <tbody>
                                    <tr>
                                      <td id="td-right">
                                        <span>contract quantity</span>
                                      </td>
                                      <td>
                                        {renderShipmentQty(data1.quantity_mt)}
                                      </td>
                                      <td id="td-right">
                                        {/* <span>Amount</span> */}
                                      </td>
                                      <td>{/* {data1.amount} */}</td>
                                    </tr>

                                    <tr>
                                      <td id="td-right">
                                      <span className="a-a">
                                product description{" "}
                                                <Tooltip text={<span>Product Specification</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                      </td>
                                      <td>{data1.product_description}</td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>

                                <div
                                  className="col-md-12"
                                  id="ax.account_details"
                                >
                                  <h4 className="heading">
                                    Market Information
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table class="table table-bordered account-table tables">
                                  <tbody>
                                    <tr>
                                      <td id="td-right">
                                        <span>Origin</span>
                                      </td>
                                      <td>{data1.origin}</td>

                                      <td id="td-right">
                                        <span>commodity</span>
                                      </td>
                                      <td>{data1.commodity}</td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>destination</span>
                                      </td>
                                      <td>{data1.destination}</td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>

                                {/* <div className="col-md-12" id="ax.account_details">
                          <h4 className="heading">
                            Price and Price Adjustment
                          </h4>
                          <hr></hr>
                        </div>
                        <table class="table table-bordered account-table tables">
                          <tbody>
                            <tr>
                            <td id="td-right">
                                <span>Bonus penalty clause</span>
                                </td><td>
                                {data1.bonus_penalty_clause}
                              </td>

                              <td id="td-right">
                                <span>Rejections</span>
                                </td><td>
                                {data1.rejections}
                              </td>

                            </tr>
                            <tr>
                            <td id="td-right">
                                <span>Payment Terms</span>
                                </td><td>
                                {data1.payment_terms}
                              </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table> */}

                                <div
                                  className="col-md-12"
                                  id="ax.account_details"
                                >
                                  <h4 className="heading">
                                    System Information
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table class="table table-bordered account-table tables">
                                  <tbody>
                                    <tr>
                                      <td id="td-right">
                                        <span>Validity</span>
                                      </td>
                                      <td>{data1.validity}</td>

                                      <td id="td-right"></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>Applicable Law</span>
                                      </td>
                                      <td>{data1.applicable_law}</td>
                                      <td id="td-right"></td>
                                      <td></td>
                                    </tr>
                                    <tr>
                                      <td id="td-right">
                                        <span>Description</span>
                                      </td>
                                      <td>{data1.description}</td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div
                                  className="col-md-12"
                                  id="ax.account_details"
                                >
                                  <h4 className="heading">
                                    System Information
                                  </h4>
                                  <hr></hr>
                                </div>
                                <table class="table table-bordered account-table tables">
                                  <tbody>
                                    <tr>
                                      <td id="td-right">
                                        <span>Created By</span>
                                      </td>
                                      <td>{data1.created_by}</td>
                                      <td id="td-right">
                                        <span>Last Modified By</span>
                                      </td>
                                      <td>{data1.modified_by}</td>
                                    </tr>
                                  </tbody>
                                </table>
                                {/* <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Products (PB Nov 2017)</span>
                                      <span>
                                        <i className=""></i>
                                        Choose Price Book
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Add Rwood Product
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row> */}

                                <Row id="table-styles">
                                  <div
                                    className="col-md-12"
                                    id="ax.account_details"
                                  >
                                    <h4 className="heading">
                                      <span>Opportunity Shipment </span>
                                      <span>
                                        <Link
                                          to={"/opp/opportunity-shipment/" + id}
                                        >
                                          <i className="fe fe-download-cloud me-2"></i>
                                          Create New Spot Shipment
                                        </Link>
                                      </span>
                                    </h4>
                                    {/* <h4 className="heading"><span>Opportunity Shipment Information</span> <span  className="links"><FaPlus/> New</span></h4> */}
                                    <p id="new-offer"></p>
                                    <hr></hr>
                                  </div>
                                  <table
                                    id="shipment"
                                    className="table table-bordered"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Opportunity Shipment Name</th>
                                        <th>Laycan</th>
                                        <th>Quantity</th>
                                        <th>Pricing</th>
                                        <th>Quantity Info</th>
                                        <th>Vessel Type</th>
                                        <th>Shipping Term</th>
                                      </tr>
                                    </thead>
                                    {shipments.length > 0 ? (
                                      <tbody>
                                        {shipments.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/opportunity/oppshipment-detail/" +
                                                    y.id
                                                  }
                                                >
                                                  {y.opp_ship_code}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>{y.laycan}</td>
                                            <td>
                                              {renderShipmentQty(y.quantity)}
                                            </td>
                                            <td>{y.pricing}</td>
                                            <td>{y.quantity_info}</td>
                                            <td>{y.vessel_type}</td>
                                            <td>{y.shipping_term}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>

                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div
                                    className="col-md-12"
                                    id="ax.account_details"
                                  >
                                    <h4 className="heading">
                                      <span>Quotes</span>{" "}
                                      <span
                                        className="links"
                                        onClick={addOffer}
                                      >
                                        {" "}
                                        Create Offer
                                      </span>
                                    </h4>
                                    <hr></hr>
                                    <p id="new-offer"></p>
                                  </div>
                                  <table
                                    id="quotes"
                                    className="table table-bordered"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Quote Number</th>
                                        <th>Status</th>
                                        <th>Quantity MT </th>
                                        <th>Product Description</th>
                                        <th>Quote Date</th>
                                      </tr>
                                    </thead>
                                    {datas.length > 0 ? (
                                      <tbody>
                                        {datas.map((y, i) => (
                                          <tr>
                                            <td>
                                              <Link
                                                to={
                                                  "/opportunity/quote-view/" +
                                                  y.id
                                                }
                                              >
                                                <span id="codes">
                                                  {y.quote_number}
                                                </span>
                                              </Link>
                                            </td>
                                            <td>{y.status}</td>
                                            <td>
                                              {renderShipmentQty(y.quantity_MT)}
                                            </td>
                                            <td>{y.product_description}</td>
                                            <td>{y.quote_date}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div
                                    className="col-md-12"
                                    id="ax.account_details"
                                  >
                                    <h4 className="heading">
                                      <span>Trade Confirmation Forms</span>{" "}
                                      <span className="links">New</span>
                                    </h4>
                                    <p id="new-offer"></p>
                                    <hr></hr>
                                  </div>
                                  <table
                                    id="tcf"
                                    className="table table-bordered"
                                  >
                                    <thead>
                                      <tr>
                                        <th>TCF No</th>
                                        <th>TCF Date</th>
                                        <th>Buyer Contract </th>
                                        <th>Order</th>
                                        <th>TCF Status </th>
                                        <th>Quote</th>
                                        <th>Quote Line Item</th>
                                      </tr>
                                    </thead>
                                    {tcf.length > 0 ? (
                                      <tbody>
                                        {tcf.map((y, i) => (
                                          <tr>
                                            <td>
                                              <span id="codes">
                                                <Link
                                                  to={
                                                    "/tcf/tcf-details/" + y.id
                                                  }
                                                >
                                                  {y.tcf_no}
                                                </Link>
                                              </span>
                                            </td>
                                            <td>{y.tcf_date}</td>
                                            <td>{y.buyer_contract}</td>

                                            <td>
                                              <Link
                                                to={
                                                  "/order/view-order-details/" +
                                                  y.rwood_order_id
                                                }
                                              >
                                                {y.order}
                                              </Link>
                                            </td>
                                            <td>{y.tcf_status}</td>
                                            <td>
                                              <Link
                                                to={`/opportunity/quote-view/${y.offer_id}`}
                                              >
                                                {y.quote}
                                              </Link>
                                            </td>
                                            <td></td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td> <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Orders</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Contact Roles</span>
                                      <span>New</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Competitors</span>
                                      <span>New</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Stage History</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Opportunity Field History</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes & Attachments</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Attach File
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>
                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Notes</span>
                                      <span>
                                        <i className=""></i>
                                        New Note
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Open Activities</span>
                                      <span>
                                        <i className=""></i>
                                        New Task
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        New Event
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>Activity History</span>
                                      <span>
                                        <i className=""></i>
                                        Log a Call
                                      </span>
                                      <span>
                                        <i className=""></i>
                                        Mail merge
                                      </span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="history" className="">
                                    <tr>
                                      <td>
                                        <p class="no-records">
                                          No records to display
                                        </p>
                                      </td>
                                    </tr>
                                  </table>
                                </Row>

                                <Row id="table-styles">
                                  <div className="col-md-12" id="head">
                                    <h4 className="heading">
                                      <span>History Information</span>
                                    </h4>
                                    <hr></hr>
                                  </div>
                                  <table id="historys" className="">
                                    <thead>
                                      <tr>
                                        <th>Field Name</th>
                                        <th>New Value</th>
                                        <th>Old Value</th>
                                        <th>Changed By</th>
                                        <th>Change Time</th>
                                      </tr>
                                    </thead>

                                    {history.length > 0 ? (
                                      <tbody>
                                        {history.map((y, i) => (
                                          <tr>
                                            <td>{y.field_name}</td>
                                            <td>{y.new_value}</td>
                                            <td>{y.old_value}</td>
                                            <td>{y.changed_by}</td>
                                            <td>{y.change_time}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    ) : (
                                      <tbody>
                                        <tr>
                                          <td></td>
                                          <td></td>
                                          <td>No data available</td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </Row>

                                <p></p>
                                <p></p>
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {data1.opportunity_record_type ===
                            "Supplier - Long Term" ? (
                              <>
                                {isEditMode ? (
                                  <div className="tasks oppEdit">
                                    <div id="messageContainer"></div>
                                    <p className="edit-btn">
                                      <input
                                        type="submit"
                                        className="account-save"
                                        onClick={handleSaveEdit}
                                        value="Save"
                                        //disabled={isSubmitted}
                                      />
                                      <button
                                        className="btn btn-primary"
                                        onClick={handleCancelEdit}
                                      >
                                        Cancel
                                      </button>
                                    </p>
                                    <Row className="mb-3">
                                      <h4 className="heading">
                                        Opportunity Information
                                      </h4>

                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingSelect"
                                          className="dropDown"
                                          label="account name"
                                        >
                                          <Form.Select
                                            aria-label="Floating label select example"
                                            name="account_name"
                                            tabIndex={1}
                                            placeholder="account name"
                                            className="error-validation"
                                            defaultValue={data1.account_name}
                                            onChange={handleChangeInput}
                                          >
                                            <option value="">Select</option>
                                            {accountName.map((x) => {
                                              return (
                                                <option value={x.account_name}>
                                                  {x.account_name}
                                                </option>
                                              );
                                            })}
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Form.Group>

                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="Opportunity record type"
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="text"
                                            tabIndex={8}
                                            placeholder="Opportunity record type"
                                            name="opportunity_record_type"
                                            className="opportunity-record-type"
                                            defaultValue={
                                              data1.opportunity_record_type ||
                                              ""
                                            }
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingSelect"
                                          className="dropDown"
                                          label="Company"
                                        >
                                          <Form.Select
                                            aria-label="Floating label select example"
                                            name="company"
                                            placeholder="Company"
                                            tabIndex={2}
                                            defaultValue={data1.company || ""}
                                            onChange={handleChangeInput}
                                          >
                                            <option value="">Select</option>
                                            {company.map((x) => {
                                              return (
                                                <option value={x.company_name}>
                                                  {x.company_name}
                                                </option>
                                              );
                                            })}
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Form.Group>

                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingSelect"
                                          label="Opportunity name"
                                          className="select-dropdown"
                                        >
                                          <Select
                                            className="select"
                                            value={opportunitydrop}
                                            onChange={setOpportunityDropdown}
                                            tabIndex={9}
                                            options={optionsOpportunity}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>

                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingSelect"
                                          className="dropDown"
                                          label="Currency"
                                        >
                                          <Form.Select
                                            aria-label="Floating label select example"
                                            name="currency"
                                            placeholder="Currency"
                                            defaultValue={data1.currency || ""}
                                            tabIndex={3}
                                            onChange={handleChangeInput}
                                          >
                                            <option value="">---None---</option>
                                            <option value="USD">USD</option>
                                            <option value="IDR">IDR</option>
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Form.Group>

                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="Opportunity Owner"
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Opportunity Owner"
                                            name="opportunity_owner"
                                            tabIndex={10}
                                            defaultValue={
                                              data1.opportunity_owner || ""
                                            }
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>

                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="Exchange rate"
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Exchange rate"
                                            name="exchange_rate"
                                            tabIndex={4}
                                            defaultValue={
                                              data1.exchange_rate || ""
                                            }
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingSelect"
                                          className="dropDown"
                                          label="Trader Name"
                                        >
                                          <Form.Select
                                            aria-label="Floating label select example"
                                            name="trader_name"
                                            tabIndex={11}
                                            placeholder="Trader Name"
                                            defaultValue={
                                              data1.trader_name || ""
                                            }
                                            onChange={handleChangeInput}
                                          >
                                            <option value="">Select</option>
                                            {rwoodusers.map((x) => {
                                              return (
                                                <option
                                                  value={x.rwood_username}
                                                >
                                                  {x.rwood_username}
                                                </option>
                                              );
                                            })}
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Form.Group>

                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="Inquiry Date"
                                          className="mb-3 datepick"
                                        >
                                          <DatePicker
                                            selectsStart
                                            // minDate={startDate}
                                            // startDate={startDate}
                                            selected={inquiryDate} placeholderText="Select"
                                            onChange={handleInquiryChange}
                                            tabIndex={5}
                                            dateFormat="dd/MM/yyyy"
                                          />
                                        </FloatingLabel>
                                        {error && (
                                          <p style={{ color: "red" }}>
                                            {error}
                                          </p>
                                        )}
                                      </Form.Group>
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="No of trade confirmation"
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="No of trade confirmation"
                                            name="no_of_trade_confirmation"
                                            tabIndex={12}
                                            defaultValue={
                                              data1.no_of_trade_confirmation
                                            }
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="Close Date"
                                          className="mb-3 datepick error-validation"
                                        >
                                          <DatePicker
                                            selectsStart
                                            // minDate={startDate}
                                            // startDate={startDate}
                                            selected={closeDate} placeholderText="Select"
                                            onChange={handleCloseChange}
                                            dateFormat="dd/MM/yyyy"
                                            tabIndex={6}
                                            onChangeRaw={
                                              handleEndDateInputChange
                                            } // Handle manual typing
                                          />
                                          {endDateError && (
                                            <Form.Text className="text-danger">
                                              {endDateError}
                                            </Form.Text>
                                          )}
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6"></Form.Group>

                                      {/* <Form.Group as={Col} md="6">
                                <FloatingLabel
                                  controlId="floatingInput"
                                  label="Primary campaign source"
                                  className="mb-3"
                                >
                                  <Form.Control
                                    type="text"
                                    placeholder="Primary campaign source"
                                    name="primary_campaign_source"
                                    defaultValue={
                                      data1.primary_campaign_source || ""
                                    }
                                    onChange={handleChangeInput}
                                  />
                                </FloatingLabel>
                              </Form.Group>

                              <Form.Group as={Col} md="6"></Form.Group> */}
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingSelect"
                                          className="dropDown"
                                          label="Stage"
                                        >
                                          <Form.Select
                                            aria-label="Floating label select example"
                                            name="stage"
                                            tabIndex={7}
                                            placeholder="Stage"
                                            defaultValue={data1.stage || ""}
                                            onChange={handleChangeInput}
                                          >
                                            <option>Select</option>
                                            <option value="Proposal">
                                              Proposal
                                            </option>
                                            <option value="Concluded">
                                              Concluded
                                            </option>
                                            <option value="Quoted">
                                              Quoted
                                            </option>
                                            <option value="Dropped">
                                              Dropped
                                            </option>
                                            <option value="Last">Last</option>
                                            <option value="Closed">
                                              Closed
                                            </option>
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                      <h4 className="heading">
                                        Description Information
                                      </h4>
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="Origin"
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Origin"
                                            name="origin"
                                            tabIndex={13}
                                            defaultValue={data1.origin || ""}
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6"></Form.Group>
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingSelect"
                                          className="dropDown"
                                          label="Laycan Year"
                                        >
                                          <Form.Select
                                            aria-label="Floating label select example"
                                            name="laycan_year"
                                            placeholder="Laycan Year"
                                            tabIndex={14}
                                            defaultValue={
                                              data1.laycan_year || ""
                                            }
                                            onChange={handleChangeInput}
                                          >
                                            <option value="">---None---</option>
                                            <option value="2021">2021</option>
                                            <option value="2022">2022</option>
                                            <option value="2023">2023</option>
                                            <option value="2024">2024</option>
                                            <option value="2025">2025</option>
                                            <option value="2026">2026</option>
                                            <option value="2015">2015</option>
                                            <option value="2019">2019</option>
                                            <option value="2020">2020</option>
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6"></Form.Group>
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingSelect"
                                          className="dropDown"
                                          label="Commodity"
                                        >
                                          <Form.Select
                                            aria-label="Floating label select example"
                                            name="commodity"
                                            tabIndex={15}
                                            placeholder="Commodity"
                                            defaultValue={data1.commodity || ""}
                                            onChange={handleChangeInput}
                                          >
                                            <option>---None---</option>
                                            <option value='Indonesian Steam Coal in Bulk ("Coal")'>
                                              Indonesian Steam Coal in Bulk
                                              ("Coal")
                                            </option>
                                            <option value="US Coal">
                                              US Coal
                                            </option>
                                            <option value="Russia Coal">
                                              Russia Coal
                                            </option>
                                            <option value="SA Coal">
                                              SA Coal
                                            </option>
                                            <option value="Clinker in Bulk">
                                              Clinker in Bulk
                                            </option>
                                            <option value="Australian Steam Coal">
                                              Australian Steam Coal
                                            </option>
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6"></Form.Group>

                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        className="textarea-half"
                                      >
                                        <FloatingLabel
                                          controlId="floatingTextarea2"
                                          label="Bonus Penalty Clause"
                                        >
                                          <Form.Control
                                            as="textarea"
                                            tabIndex={16}
                                            placeholder="Bonus Penalty Clause"
                                            style={{ height: "200px" }}
                                            name="bonus_penalty_clause"
                                            defaultValue={
                                              data1.bonus_penalty_clause || ""
                                            }
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6"></Form.Group>
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        className="textarea-half"
                                      >
                                        <FloatingLabel
                                          controlId="floatingTextarea2"
                                          label="Payment terms"
                                        >
                                          <Form.Control
                                            as="textarea"
                                            tabIndex={17}
                                            placeholder="Payment terms"
                                            style={{ height: "200px" }}
                                            name="payment_terms"
                                            defaultValue={
                                              data1.payment_terms || ""
                                            }
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6"></Form.Group>
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        className="textarea-half"
                                      >
                                        <FloatingLabel
                                          controlId="floatingTextarea2"
                                          label="Rejections"
                                        >
                                          <Form.Control
                                            as="textarea"
                                            placeholder="Rejections"
                                            style={{ height: "200px" }}
                                            tabIndex={18}
                                            name="rejections"
                                            defaultValue={
                                              data1.rejections || ""
                                            }
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6"></Form.Group>
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingSelect"
                                          className="dropDown"
                                          label="Applicable Law"
                                        >
                                          <Form.Select
                                            aria-label="Floating label select example"
                                            name="applicable_law"
                                            placeholder="Applicable Law"
                                            tabIndex={19}
                                            defaultValue={
                                              data1.applicable_law || ""
                                            }
                                            onChange={handleChangeInput}
                                          >
                                            <option value="">--None--</option>
                                            <option value="English Law">
                                              English Law
                                            </option>
                                            <option value="Singapore Law">
                                              Singapore Law
                                            </option>
                                            <option value="Indonesian Law">
                                              Indonesian Law
                                            </option>
                                          </Form.Select>
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6"></Form.Group>
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="Validity"
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="Validity"
                                            name="validity"
                                            tabIndex={20}
                                            defaultValue={data1.validity || ""}
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                      <h4 className="heading">
                                        Product Information
                                      </h4>
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        className="textarea-half tooltips"
                                      >
                                        <FloatingLabel
                                          controlId="floatingTextarea2"
                                          label="Product Description"
                                        >
                                          <Form.Control
                                            as="textarea"
                                            placeholder="Product Description"
                                            tabIndex={21}
                                            style={{ height: "200px" }}
                                            name="product_description"
                                            defaultValue={
                                              data1.product_description || ""
                                            }
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                        <Tooltip text={<span>Product Specification</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="Next Step"
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="text"
                                            tabIndex={25}
                                            placeholder="Next Step"
                                            name="next_step"
                                            defaultValue={data1.next_step || ""}
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group
                                        as={Col}
                                        md="6"
                                        className="textarea-half"
                                      >
                                        <FloatingLabel
                                          controlId="floatingTextarea2"
                                          label="Description"
                                        >
                                          <Form.Control
                                            as="textarea"
                                            placeholder="Description"
                                            style={{ height: "200px" }}
                                            name="description"
                                            tabIndex={22}
                                            defaultValue={
                                              data1.description || ""
                                            }
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6"></Form.Group>{" "}
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          abel="contract quantity"
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="contract quantity"
                                            name="quantity_mt"
                                            tabIndex={23}
                                            defaultValue={
                                              data1.quantity_mt || ""
                                            }
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>
                                      <Form.Group as={Col} md="6"></Form.Group>{" "}
                                      <Form.Group as={Col} md="6">
                                        <FloatingLabel
                                          controlId="floatingInput"
                                          label="shipment quantity "
                                          className="mb-3"
                                        >
                                          <Form.Control
                                            type="text"
                                            placeholder="shipment quantity"
                                            tabIndex={24}
                                            name="quantity"
                                            defaultValue={data1.quantity || ""}
                                            onChange={handleChangeInput}
                                          />
                                        </FloatingLabel>
                                      </Form.Group>{" "}
                                    </Row>
                                    <p className="edit-btn">
                                      <input
                                        type="submit"
                                        className="account-save"
                                        onClick={handleSaveEdit}
                                        value="Save"
                                        //disabled={isSubmitted}
                                      />
                                      <button
                                        className="btn btn-primary"
                                        onClick={handleCancelEdit}
                                      >
                                        Cancel
                                      </button>
                                    </p>
                                  </div>
                                ) : (
                                  <div className="tasks">
                                    <div className="edit-delete">
                                      <span id="edit" onClick={handleEditClick}>
                                        Edit
                                      </span>
                                      <span>
                                        <Link
                                          to={"/opportunity/create-view-list"}
                                        >
                                          {" "}
                                          Cancel
                                        </Link>
                                      </span>
                                      <span>Submit for Approval</span>
                                      <span>New Order</span>
                                    </div>
                                    <div
                                      className="col-md-12"
                                      id="ax.account_details"
                                    >
                                      <h4 className="heading">
                                        Opportunity Information
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table class="table table-bordered account-table tables">
                                      <tr>
                                        <td id="td-right">
                                          <span>account name</span>
                                        </td>
                                        <td>
                                          <Link
                                            to={"/accounts/" + data1.account_id}
                                          >
                                            {" "}
                                            {data1.account_name}{" "}
                                          </Link>
                                        </td>
                                        <td id="td-right">
                                          <span>Opportunity Record Type</span>
                                        </td>
                                        <td>{data1.opportunity_record_type}</td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Company</span>
                                        </td>
                                        <td>
                                          <Link
                                            to={`/company/company-details/${data1.rwood_company_id}`}
                                          >
                                            {data1.company}
                                          </Link>
                                        </td>
                                        <td id="td-right">
                                          <span>opportunity name</span>
                                        </td>
                                        <td>{data1.opportunity_name}</td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>currency </span>
                                        </td>
                                        <td>{data1.currency}</td>
                                        <td id="td-right">
                                          <span>opportunity Owner </span>
                                        </td>
                                        <td>{data1.opportunity_owner}</td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>Exchange Rate </span>
                                        </td>
                                        <td>{data1.exchange_rate}</td>
                                        <td id="td-right">
                                          <span>trader name</span>
                                        </td>
                                        <td>
                                          <Link
                                            to={
                                              "/user/user-details/" +
                                              data1.rwood_users_id
                                            }
                                          >
                                            {" "}
                                            {data1.trader_name}{" "}
                                          </Link>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>inquiry date </span>
                                        </td>
                                        <td>{data1.inquiry_date}</td>
                                        <td id="td-right">
                                          <span>no of trade confirmation</span>
                                        </td>
                                        <td>
                                          {data1.no_of_trade_confirmation}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>close date</span>
                                        </td>
                                        <td>{data1.close_date}</td>
                                        <td id="td-right">
                                          {/* <span>close date</span> */}
                                        </td>
                                        <td>{/* {data1.close_date} */}</td>
                                      </tr>

                                      <tr>
                                        <td id="td-right">
                                          <span>stage</span>
                                        </td>
                                        <td>{data1.stage}</td>
                                        <td id="td-right">
                                          {/* <span>primary campaign source</span> */}
                                        </td>
                                        <td>
                                          {/* {data1.primary_campaign_source} */}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td id="td-right">
                                          <span>No of Opp Shipments</span>
                                        </td>
                                        <td>{data1.no_of_opp_shipments}</td>
                                        <td id="td-right"></td>
                                        <td></td>
                                      </tr>
                                    </table>

                                    <div
                                      className="col-md-12"
                                      id="ax.account_details"
                                    >
                                      <h4 className="heading">
                                        Description Information
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table class="table table-bordered account-table tables">
                                      <tbody>
                                        <tr>
                                          <td id="td-right">
                                            <span>Origin</span>
                                          </td>
                                          <td>{data1.origin}</td>
                                          <td id="td-right">
                                            {/* <span>Origin</span> */}
                                          </td>
                                          <td>{/* {data1.origin} */}</td>
                                        </tr>
                                        <tr>
                                          <td id="td-right">
                                            <span>laycan year</span>
                                          </td>
                                          <td>{data1.laycan_year}</td>
                                          <td id="td-right">
                                            {/* <span>laycan year</span> */}
                                          </td>
                                          <td>{/* {data1.laycan_year} */}</td>
                                        </tr>
                                        <tr>
                                          <td id="td-right">
                                            <span>commodity</span>
                                          </td>
                                          <td>{data1.commodity}</td>
                                          <td id="td-right">
                                            {/* <span>commodity</span> */}
                                          </td>
                                          <td>{/* {data1.commodity} */}</td>
                                        </tr>
                                        <tr>
                                          <td id="td-right">
                                            <span>Bonus penalty clause</span>
                                          </td>
                                          <td>{data1.bonus_penalty_clause}</td>
                                          <td id="td-right">
                                            {/* <span>Bonus penalty clause</span> */}
                                          </td>
                                          <td>
                                            {/* {data1.bonus_penalty_clause} */}
                                          </td>
                                        </tr>

                                        <tr>
                                          <td id="td-right">
                                            <span>Payment Terms</span>
                                          </td>
                                          <td>{data1.payment_terms}</td>
                                          <td id="td-right">
                                            {/* <span>Payment Terms</span> */}
                                          </td>
                                          <td>{/* {data1.payment_terms} */}</td>
                                        </tr>
                                        <tr>
                                          <td id="td-right">
                                            <span>Rejections</span>
                                          </td>
                                          <td>{data1.rejections}</td>

                                          <td id="td-right">
                                            {/* <span>Rejections</span> */}
                                          </td>
                                          <td>{/* {data1.rejections} */}</td>
                                        </tr>
                                        <tr>
                                          <td id="td-right">
                                            <span>Applicable Law</span>
                                          </td>
                                          <td>{data1.applicable_law}</td>
                                          <td id="td-right">
                                            {/* <span>Applicable Law</span> */}
                                          </td>
                                          <td>
                                            {/* {data1.applicable_law} */}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td id="td-right">
                                            <span>Validity</span>
                                          </td>
                                          <td>{data1.validity}</td>
                                          <td id="td-right">
                                            {/* <span>Validity</span> */}
                                          </td>
                                          <td>{/* {data1.validity} */}</td>
                                        </tr>
                                      </tbody>
                                    </table>

                                    <div
                                      className="col-md-12"
                                      id="ax.account_details"
                                    >
                                      <h4 className="heading">
                                        Product Information
                                      </h4>
                                      <hr></hr>
                                    </div>
                                    <table class="table table-bordered account-table tables">
                                      <tbody>
                                        <tr>
                                          <td id="td-right">
                                          <span className="a-a">
                                          
                                product description{" "}
                                                <Tooltip text={<span>Product Specification</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                          </td>
                                          <td>{data1.product_description}</td>
                                          <td id="td-right">
                                            <span>next step</span>
                                          </td>
                                          <td>{data1.next_step}</td>
                                        </tr>
                                        <tr>
                                          <td id="td-right">
                                            <span>description</span>
                                          </td>
                                          <td>{data1.description}</td>
                                          <td id="td-right">
                                            {/* <span>description</span> */}
                                          </td>
                                          <td>{/* {data1.description} */}</td>
                                        </tr>
                                        <tr>
                                          <td id="td-right">
                                            <span>quantity mt</span>
                                          </td>
                                          <td>
                                            {renderShipmentQty(
                                              data1.quantity_mt
                                            )}
                                          </td>
                                          <td id="td-right">
                                            {/* <span>product description</span> */}
                                          </td>
                                          <td>
                                            {/* {data1.product_description} */}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td id="td-right">
                                            <span>shipment quantity</span>
                                          </td>
                                          <td>
                                            {renderShipmentQty(data1.quantity)}
                                          </td>
                                          <td id="td-right">
                                            {/* <span>product description</span> */}
                                          </td>
                                          <td>
                                            {/* {data1.product_description} */}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>

                                    <Row id="table-styles"></Row>

                                    <Row id="table-styles">
                                      <div
                                        className="col-md-12"
                                        id="ax.account_details"
                                      >
                                        <h4 className="heading">
                                          <span>
                                            Opportunity Shipment Information
                                          </span>
                                          <span>
                                            <Link
                                              to={
                                                "/opp/opportunity-shipment/" +
                                                id
                                              }
                                            >
                                              <i className="fe fe-download-cloud me-2"></i>
                                              New
                                            </Link>
                                          </span>
                                        </h4>
                                        {/* <h4 className="heading"><span>Opportunity Shipment Information</span> <span  className="links"><FaPlus/> New</span></h4> */}
                                        <p id="new-offer"></p>
                                        <hr></hr>
                                      </div>
                                      <table
                                        id="shipment"
                                        className="table table-bordered"
                                      >
                                        <thead>
                                          <tr>
                                            <th>Opportunity Shipment Name</th>
                                            <th>Laycan</th>
                                            <th>Quantity</th>
                                            <th>Pricing</th>
                                            <th>Quantity Info</th>
                                            <th>Vessel Type</th>
                                            <th>Shipping Term</th>
                                          </tr>
                                        </thead>
                                        {shipments.length > 0 ? (
                                          <tbody>
                                            {shipments.map((y, i) => (
                                              <tr>
                                                <td>
                                                  <span id="codes">
                                                    <Link
                                                      to={
                                                        "/opportunity/oppshipment-detail/" +
                                                        y.id
                                                      }
                                                    >
                                                      {y.opp_ship_code}
                                                    </Link>
                                                  </span>
                                                </td>
                                                <td>{y.laycan}</td>
                                                <td>
                                                  {renderShipmentQty(
                                                    y.quantity
                                                  )}
                                                </td>
                                                <td>{y.pricing}</td>
                                                <td>{y.quantity_info}</td>
                                                <td>{y.vessel_type}</td>
                                                <td>{y.shipping_term}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        ) : (
                                          <tbody>
                                            <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>No data available</td>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                          </tbody>
                                        )}
                                      </table>
                                    </Row>

                                    <Row id="table-styles">
                                      <div
                                        className="col-md-12"
                                        id="ax.account_details"
                                      >
                                        <h4 className="heading">
                                          <span>Trade Confirmation Forms</span>{" "}
                                          <span className="links">New</span>
                                        </h4>
                                        <p id="new-offer"></p>
                                        <hr></hr>
                                      </div>
                                      <table
                                        id="tcf"
                                        className="table table-bordered"
                                      >
                                        <thead>
                                          <tr>
                                            <th>TCF No</th>
                                            <th>TCF Date</th>
                                            <th>Buyer Contract </th>
                                            <th>Order</th>
                                            <th>TCF Status </th>
                                            <th>Quote</th>
                                            <th>Quote Line Item</th>
                                          </tr>
                                        </thead>
                                        {tcf.length > 0 ? (
                                          <tbody>
                                            {tcf.map((y, i) => (
                                              <tr>
                                                <td>
                                                  <span id="codes">
                                                    <Link
                                                      to={
                                                        "/tcf/tcf-details/" +
                                                        y.id
                                                      }
                                                    >
                                                      {y.tcf_no}
                                                    </Link>
                                                  </span>
                                                </td>
                                                <td>{y.tcf_date}</td>
                                                <td>{y.buyer_contract}</td>

                                                <td>
                                                  <Link
                                                    to={
                                                      "/order/view-order-details/" +
                                                      y.rwood_order_id
                                                    }
                                                  >
                                                    {y.order}
                                                  </Link>
                                                </td>
                                                <td>{y.tcf_status}</td>
                                                <td>
                                                  <Link
                                                    to={`/opportunity/quote-view/${y.offer_id}`}
                                                  >
                                                    {y.quote}
                                                  </Link>
                                                </td>
                                                <td></td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        ) : (
                                          <tbody>
                                            <tr>
                                              <td></td>
                                              <td></td>
                                              <td></td>
                                              <td>No data available</td>
                                              <td></td>
                                              <td></td> <td></td>
                                            </tr>
                                          </tbody>
                                        )}
                                      </table>
                                    </Row>

                                    <Row id="table-styles">
                                      <div className="col-md-12" id="head">
                                        <h4 className="heading">
                                          <span>
                                            Products (Standard Price Book)
                                          </span>
                                          <span>
                                            <i className=""></i>
                                            Add Product
                                          </span>
                                          <span>Choose Price Book</span>
                                          <span>Add Rwood Product</span>
                                        </h4>
                                        <hr></hr>
                                      </div>
                                      <table id="history" className="">
                                        <tr>
                                          <td>
                                            <p class="no-records">
                                              No records to display
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </Row>

                                    <Row id="table-styles">
                                      <div className="col-md-12" id="head">
                                        <h4 className="heading">
                                          <span>Contact Roles</span>
                                          <span>New</span>
                                        </h4>
                                        <hr></hr>
                                      </div>
                                      <table id="history" className="">
                                        <tr>
                                          <td>
                                            <p class="no-records">
                                              No records to display
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </Row>

                                    <Row id="table-styles">
                                      <div className="col-md-12" id="head">
                                        <h4 className="heading">
                                          <span>Approval History</span>
                                          <span>Submit for Approval</span>
                                        </h4>
                                        <hr></hr>
                                      </div>
                                      <table id="history" className="">
                                        <tr>
                                          <td>
                                            <p class="no-records">
                                              No records to display
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </Row>

                                    <Row id="table-styles">
                                      <div className="col-md-12" id="head">
                                        <h4 className="heading">
                                          <span>Competitors</span>
                                          <span>New</span>
                                        </h4>
                                        <hr></hr>
                                      </div>
                                      <table id="history" className="">
                                        <tr>
                                          <td>
                                            <p class="no-records">
                                              No records to display
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </Row>

                                    <Row id="table-styles">
                                      <div className="col-md-12" id="head">
                                        <h4 className="heading">
                                          <span>Files</span>
                                          <span>Upload File</span>
                                        </h4>
                                        <hr></hr>
                                      </div>
                                      <table id="history" className="">
                                        <tr>
                                          <td>
                                            <p class="no-records">
                                              No records to display
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </Row>

                                    <Row id="table-styles">
                                      <div
                                        className="col-md-12"
                                        id="ax.account_details"
                                      >
                                        <h4 className="heading">
                                          <span>Quotes Information</span>{" "}
                                          <span
                                            className="links"
                                            onClick={addOffer}
                                          >
                                            {" "}
                                            Create Offer
                                          </span>
                                        </h4>
                                        <hr></hr>
                                        <p id="new-offer"></p>
                                      </div>
                                      <table
                                        id="quotes"
                                        className="table table-bordered"
                                      >
                                        <thead>
                                          <tr>
                                            <th>Quote Number</th>
                                            <th>Status</th>
                                            <th>Quantity MT </th>
                                            <th>Product Description</th>
                                            <th>Quote Date</th>
                                          </tr>
                                        </thead>
                                        {datas.length > 0 ? (
                                          <tbody>
                                            {datas.map((y, i) => (
                                              <tr>
                                                <td>
                                                  <Link
                                                    to={
                                                      "/opportunity/quote-view/" +
                                                      y.id
                                                    }
                                                  >
                                                    <span id="codes">
                                                      {y.quote_number}
                                                    </span>
                                                  </Link>
                                                </td>
                                                <td>{y.status}</td>
                                                <td>
                                                  {renderShipmentQty(
                                                    y.quantity_MT
                                                  )}
                                                </td>
                                                <td>{y.product_description}</td>
                                                <td>{y.quote_date}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        ) : (
                                          <tbody>
                                            <tr>
                                              <td></td>
                                              <td></td>
                                              <td>No data available</td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                          </tbody>
                                        )}
                                      </table>
                                    </Row>

                                    <Row id="table-styles">
                                      <div className="col-md-12" id="head">
                                        <h4 className="heading">
                                          <span>Orders</span>
                                          <span>New Order</span>
                                        </h4>
                                        <hr></hr>
                                      </div>
                                      <table id="history" className="">
                                        <tr>
                                          <td>
                                            <p class="no-records">
                                              No records to display
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </Row>
                                    <Row id="table-styles">
                                      <div className="col-md-12" id="head">
                                        <h4 className="heading">
                                          <span>Notes</span>
                                          <span>
                                            <i className=""></i>
                                            New Note
                                          </span>
                                        </h4>
                                        <hr></hr>
                                      </div>
                                      <table id="history" className="">
                                        <tr>
                                          <td>
                                            <p class="no-records">
                                              No records to display
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </Row>

                                    <Row id="table-styles">
                                      <div className="col-md-12" id="head">
                                        <h4 className="heading">
                                          <span>Open Activities</span>
                                          <span>
                                            <i className=""></i>
                                            New Task
                                          </span>
                                          <span>
                                            <i className=""></i>
                                            New Event
                                          </span>
                                        </h4>
                                        <hr></hr>
                                      </div>
                                      <table id="history" className="">
                                        <tr>
                                          <td>
                                            <p class="no-records">
                                              No records to display
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </Row>
                                    <Row id="table-styles">
                                      <div className="col-md-12" id="head">
                                        <h4 className="heading">
                                          <span>Activity History</span>
                                          <span>
                                            <i className=""></i>
                                            Log a Call
                                          </span>
                                          <span>
                                            <i className=""></i>
                                            Mail merge
                                          </span>
                                        </h4>
                                        <hr></hr>
                                      </div>
                                      <table id="history" className="">
                                        <tr>
                                          <td>
                                            <p class="no-records">
                                              No records to display
                                            </p>
                                          </td>
                                        </tr>
                                      </table>
                                    </Row>
                                    <Row id="table-styles">
                                      <div className="col-md-12" id="head">
                                        <h4 className="heading">
                                          <span>History Information</span>
                                        </h4>
                                        <hr></hr>
                                      </div>
                                      <table id="historys" className="">
                                        <thead>
                                          <tr>
                                            <th>Field Name</th>
                                            <th>New Value</th>
                                            <th>Old Value</th>
                                            <th>Changed By</th>
                                            <th>Change Time</th>
                                          </tr>
                                        </thead>

                                        {history.length > 0 ? (
                                          <tbody>
                                            {history.map((y, i) => (
                                              <tr>
                                                <td>{y.field_name}</td>
                                                <td>{y.new_value}</td>
                                                <td>{y.old_value}</td>
                                                <td>{y.changed_by}</td>
                                                <td>{y.change_time}</td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        ) : (
                                          <tbody>
                                            <tr>
                                              <td></td>
                                              <td></td>
                                              <td>No data available</td>
                                              <td></td>
                                              <td></td>
                                            </tr>
                                          </tbody>
                                        )}
                                      </table>
                                    </Row>
                                    <p></p>
                                    <p></p>
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                <>
                                  {data1.opportunity_record_type ===
                                  "Supplier - Spot" ? (
                                    <>
                                      {isEditMode ? (
                                        <div className="tasks oppEdit">
                                          <div id="messageContainer"></div>
                                          <p className="edit-btn">
                                            <input
                                              type="submit"
                                              className="account-save"
                                              onClick={handleSaveEdit}
                                              value="Save"
                                              //disabled={isSubmitted}
                                            />
                                            <button
                                              className="btn btn-primary"
                                              onClick={handleCancelEdit}
                                            >
                                              Cancel
                                            </button>
                                          </p>
                                          <Row className="mb-3">
                                            <h4 className="heading">
                                              Opportunity Information
                                            </h4>

                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingSelect"
                                                className="dropDown"
                                                label="account name"
                                              >
                                                <Form.Select
                                                  aria-label="Floating label select example"
                                                  name="account_name"
                                                  placeholder="account name"
                                                  tabIndex={1}
                                                  className="error-validation"
                                                  defaultValue={
                                                    data1.account_name
                                                  }
                                                  onChange={handleChangeInput}
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  {accountName.map((x) => {
                                                    return (
                                                      <option
                                                        value={x.account_name}
                                                      >
                                                        {x.account_name}
                                                      </option>
                                                    );
                                                  })}
                                                </Form.Select>
                                              </FloatingLabel>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingInput"
                                                label="Opportunity record type"
                                                className="mb-3"
                                              >
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Opportunity record type"
                                                  tabIndex={9}
                                                  name="opportunity_record_type"
                                                  className="opportunity-record-type"
                                                  defaultValue={
                                                    data1.opportunity_record_type ||
                                                    ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingSelect"
                                                className="dropDown"
                                                label="Company"
                                              >
                                                <Form.Select
                                                  aria-label="Floating label select example"
                                                  name="company"
                                                  tabIndex={2}
                                                  placeholder="Company"
                                                  defaultValue={
                                                    data1.company || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  {company.map((x) => {
                                                    return (
                                                      <option
                                                        value={x.company_name}
                                                      >
                                                        {x.company_name}
                                                      </option>
                                                    );
                                                  })}
                                                </Form.Select>
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingSelect"
                                                label="Opportunity name"
                                                className="select-dropdown"
                                              >
                                                <Select
                                                  className="select"
                                                  tabIndex={10}
                                                  value={opportunitydrop}
                                                  onChange={
                                                    setOpportunityDropdown
                                                  }
                                                  options={optionsOpportunity}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingSelect"
                                                className="dropDown"
                                                label="Currency"
                                              >
                                                <Form.Select
                                                  aria-label="Floating label select example"
                                                  name="currency"
                                                  tabIndex={3}
                                                  placeholder="Currency"
                                                  defaultValue={
                                                    data1.currency || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                >
                                                  <option value="">
                                                    ---None---
                                                  </option>
                                                  <option value="USD">
                                                    USD
                                                  </option>
                                                  <option value="IDR">
                                                    IDR
                                                  </option>
                                                </Form.Select>
                                              </FloatingLabel>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingInput"
                                                label="Opportunity Owner"
                                                className="mb-3"
                                              >
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Opportunity Owner"
                                                  name="opportunity_owner"
                                                  tabIndex={11}
                                                  defaultValue={
                                                    data1.opportunity_owner ||
                                                    ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingInput"
                                                label="Exchange rate"
                                                className="mb-3"
                                              >
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Exchange rate"
                                                  name="exchange_rate"
                                                  tabIndex={4}
                                                  defaultValue={
                                                    data1.exchange_rate || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingInput"
                                                label="No of trade confirmation"
                                                className="mb-3"
                                              >
                                                <Form.Control
                                                  type="text"
                                                  placeholder="No of trade confirmation"
                                                  name="no_of_trade_confirmation"
                                                  tabIndex={12}
                                                  defaultValue={
                                                    data1.no_of_trade_confirmation
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                                {/* {errors.no_of_trade_confirmation && <span>Required</span>} */}
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingSelect"
                                                className="dropDown"
                                                label="Trader Name"
                                              >
                                                <Form.Select
                                                  aria-label="Floating label select example"
                                                  name="trader_name"
                                                  placeholder="Trader Name"
                                                  tabIndex={5}
                                                  defaultValue={
                                                    data1.trader_name || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                >
                                                  <option value="">
                                                    Select
                                                  </option>
                                                  {rwoodusers.map((x) => {
                                                    return (
                                                      <option
                                                        value={x.rwood_username}
                                                      >
                                                        {x.rwood_username}
                                                      </option>
                                                    );
                                                  })}
                                                </Form.Select>
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>
                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingInput"
                                                label="Inquiry Date"
                                                className="mb-3 datepick"
                                              >
                                                <DatePicker
                                                  selectsStart
                                                  tabIndex={6}
                                                  // minDate={startDate}
                                                  // startDate={startDate}
                                                  selected={inquiryDate} placeholderText="Select"
                                                  onChange={handleInquiryChange}
                                                  dateFormat="dd/MM/yyyy"
                                                />
                                              </FloatingLabel>
                                              {error && (
                                                <p style={{ color: "red" }}>
                                                  {error}
                                                </p>
                                              )}
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>
                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingInput"
                                                label="Close Date"
                                                className="mb-3 datepick error-validation"
                                              >
                                                <DatePicker
                                                  selectsStart
                                                  // minDate={startDate}
                                                  // startDate={startDate}
                                                  selected={closeDate} placeholderText="Select"
                                                  tabIndex={7}
                                                  onChange={handleCloseChange}
                                                  dateFormat="dd/MM/yyyy"
                                                  onChangeRaw={
                                                    handleEndDateInputChange
                                                  } // Handle manual typing
                                                />
                                                {endDateError && (
                                                  <Form.Text className="text-danger">
                                                    {endDateError}
                                                  </Form.Text>
                                                )}
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>

                                            {/* <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="Primary campaign source"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Primary campaign source"
                                        name="primary_campaign_source"
                                        defaultValue={
                                          data1.primary_campaign_source || ""
                                        }
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group> */}

                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingSelect"
                                                className="dropDown"
                                                label="Stage"
                                              >
                                                <Form.Select
                                                  aria-label="Floating label select example"
                                                  name="stage"
                                                  tabIndex={8}
                                                  placeholder="Stage"
                                                  defaultValue={
                                                    data1.stage || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                >
                                                  <option>Select</option>
                                                  <option value="Proposal">
                                                    Proposal
                                                  </option>
                                                  <option value="Concluded">
                                                    Concluded
                                                  </option>
                                                  <option value="Quoted">
                                                    Quoted
                                                  </option>
                                                  <option value="Dropped">
                                                    Dropped
                                                  </option>
                                                  <option value="Last">
                                                    Last
                                                  </option>
                                                  <option value="Closed">
                                                    Closed
                                                  </option>
                                                </Form.Select>
                                              </FloatingLabel>
                                            </Form.Group>
                                          </Row>

                                          <Row className="mb-3">
                                            <h4 className="heading">
                                              Description Information
                                            </h4>
                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingSelect"
                                                className="dropDown"
                                                label="Laycan Year"
                                              >
                                                <Form.Select
                                                  aria-label="Floating label select example"
                                                  name="laycan_year"
                                                  tabIndex={13}
                                                  placeholder="Laycan Year"
                                                  className="error-validation"
                                                  defaultValue={
                                                    data1.laycan_year || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                >
                                                  <option value="">
                                                    ---None---
                                                  </option>
                                                  <option value="2021">
                                                    2021
                                                  </option>
                                                  <option value="2022">
                                                    2022
                                                  </option>
                                                  <option value="2023">
                                                    2023
                                                  </option>
                                                  <option value="2024">
                                                    2024
                                                  </option>
                                                  <option value="2025">
                                                    2025
                                                  </option>
                                                  <option value="2026">
                                                    2026
                                                  </option>
                                                  <option value="2015">
                                                    2015
                                                  </option>
                                                  <option value="2019">
                                                    2019
                                                  </option>
                                                  <option value="2020">
                                                    2020
                                                  </option>
                                                </Form.Select>
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>
                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingInput"
                                                label="Origin"
                                                className="mb-3"
                                              >
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Origin"
                                                  name="origin"
                                                  tabIndex={14}
                                                  defaultValue={
                                                    data1.origin || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>
                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingSelect"
                                                className="dropDown"
                                                label="Commodity"
                                              >
                                                <Form.Select
                                                  aria-label="Floating label select example"
                                                  name="commodity"
                                                  placeholder="Commodity"
                                                  tabIndex={15}
                                                  className="error-validation"
                                                  defaultValue={
                                                    data1.commodity || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                >
                                                  <option value="">
                                                    ---None---
                                                  </option>
                                                  <option value='Indonesian Steam Coal in Bulk ("Coal")'>
                                                    Indonesian Steam Coal in
                                                    Bulk ("Coal")
                                                  </option>
                                                  <option value="US Coal">
                                                    US Coal
                                                  </option>
                                                  <option value="Russia Coal">
                                                    Russia Coal
                                                  </option>
                                                  <option value="SA Coal">
                                                    SA Coal
                                                  </option>
                                                  <option value="Clinker in Bulk">
                                                    Clinker in Bulk
                                                  </option>
                                                  <option value="Australian Steam Coal">
                                                    Australian Steam Coal
                                                  </option>
                                                </Form.Select>
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                              className="textarea-half"
                                            >
                                              <FloatingLabel
                                                controlId="floatingTextarea2"
                                                label="Bonus Penalty Clause"
                                              >
                                                <Form.Control
                                                  as="textarea"
                                                  tabIndex={16}
                                                  placeholder="Bonus Penalty Clause"
                                                  style={{ height: "200px" }}
                                                  name="bonus_penalty_clause"
                                                  defaultValue={
                                                    data1.bonus_penalty_clause ||
                                                    ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                              className="textarea-half"
                                            >
                                              <FloatingLabel
                                                controlId="floatingTextarea2"
                                                label="Payment terms"
                                              >
                                                <Form.Control
                                                  as="textarea"
                                                  tabIndex={17}
                                                  placeholder="Payment terms"
                                                  style={{ height: "200px" }}
                                                  name="payment_terms"
                                                  defaultValue={
                                                    data1.payment_terms || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                              className="textarea-half"
                                            >
                                              <FloatingLabel
                                                controlId="floatingTextarea2"
                                                label="Rejections"
                                              >
                                                <Form.Control
                                                  as="textarea"
                                                  placeholder="Rejections"
                                                  style={{ height: "200px" }}
                                                  name="rejections"
                                                  tabIndex={18}
                                                  defaultValue={
                                                    data1.rejections || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>
                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingSelect"
                                                className="dropDown"
                                                label="Applicable Law"
                                              >
                                                <Form.Select
                                                  aria-label="Floating label select example"
                                                  name="applicable_law"
                                                  tabIndex={19}
                                                  placeholder="Applicable Law"
                                                  defaultValue={
                                                    data1.applicable_law || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                >
                                                  <option value="">
                                                    --None--
                                                  </option>
                                                  <option value="English Law">
                                                    English Law
                                                  </option>
                                                  <option value="Singapore Law">
                                                    Singapore Law
                                                  </option>
                                                  <option value="Indonesian Law">
                                                    Indonesian Law
                                                  </option>
                                                </Form.Select>
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>
                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingInput"
                                                label="Validity"
                                                className="mb-3"
                                              >
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Validity"
                                                  name="validity"
                                                  tabIndex={20}
                                                  defaultValue={
                                                    data1.validity || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>
                                          </Row>
                                          <Row className="mb-3">
                                            <h4 className="heading">
                                              Product Information
                                            </h4>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                              className="textarea-half tooltips"
                                            >
                                              <FloatingLabel
                                                controlId="floatingTextarea2"
                                                label="Product Description"
                                              >
                                                <Form.Control
                                                  as="textarea"
                                                  tabIndex={21}
                                                  placeholder="Product Description"
                                                  style={{ height: "200px" }}
                                                  name="product_description"
                                                  defaultValue={
                                                    data1.product_description ||
                                                    ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                              <Tooltip text={<span>Product Specification</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                            </Form.Group>

                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingInput"
                                                label="Next Step"
                                                className="mb-3"
                                              >
                                                <Form.Control
                                                  type="text"
                                                  placeholder="Next Step"
                                                  name="next_step"
                                                  tabIndex={25}
                                                  defaultValue={
                                                    data1.next_step || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                              className="textarea-half"
                                            >
                                              <FloatingLabel
                                                controlId="floatingTextarea2"
                                                label="Description"
                                              >
                                                <Form.Control
                                                  as="textarea"
                                                  tabIndex={22}
                                                  placeholder="Description"
                                                  style={{ height: "200px" }}
                                                  name="description"
                                                  defaultValue={
                                                    data1.description || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>
                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingInput"
                                                label="contract quantity"
                                                className="mb-3"
                                              >
                                                <Form.Control
                                                  type="text"
                                                  placeholder="contract quantity"
                                                  tabIndex={23}
                                                   name="quantity_mt"
                                                  defaultValue={
                                                    data1.quantity_mt || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>
                                            <Form.Group as={Col} md="6">
                                              <FloatingLabel
                                                controlId="floatingInput"
                                                label="shipment quantity "
                                                className="mb-3"
                                              >
                                                <Form.Control
                                                  type="text"
                                                  placeholder="shipment quantity"
                                                  name="quantity"
                                                  tabIndex={24}
                                                  defaultValue={
                                                    data1.quantity || ""
                                                  }
                                                  onChange={handleChangeInput}
                                                />
                                              </FloatingLabel>
                                            </Form.Group>
                                            <Form.Group
                                              as={Col}
                                              md="6"
                                            ></Form.Group>
                                            {/* <Form.Group as={Col} md="6">
                                    <FloatingLabel
                                      controlId="floatingInput"
                                      label="amount"
                                      className="mb-3"
                                    >
                                      <Form.Control
                                        type="text"
                                        placeholder="Amount"
                                        name="amount"
                                        defaultValue={data1.amount || ""}
                                        onChange={handleChangeInput}
                                      />
                                    </FloatingLabel>
                                  </Form.Group>{" "} */}
                                          </Row>
                                          <p className="edit-btn">
                                            <input
                                              type="submit"
                                              className="account-save"
                                              onClick={handleSaveEdit}
                                              value="Save"
                                              //disabled={isSubmitted}
                                            />
                                            <button
                                              className="btn btn-primary"
                                              onClick={handleCancelEdit}
                                            >
                                              Cancel
                                            </button>
                                          </p>
                                        </div>
                                      ) : (
                                        <div className="tasks">
                                          <div className="edit-delete">
                                            <span
                                              id="edit"
                                              onClick={handleEditClick}
                                            >
                                              Edit
                                            </span>
                                            <span>
                                              <Link
                                                to={
                                                  "/opportunity/create-view-list"
                                                }
                                              >
                                                {" "}
                                                Cancel
                                              </Link>
                                            </span>
                                            <span>Submit for Approval</span>
                                            <span>New Order</span>
                                          </div>
                                          <div
                                            className="col-md-12"
                                            id="ax.account_details"
                                          >
                                            <h4 className="heading">
                                              Opportunity Information
                                            </h4>
                                            <hr></hr>
                                          </div>
                                          <table class="table table-bordered account-table tables">
                                            <tr>
                                              <td id="td-right">
                                                <span>account name</span>
                                              </td>
                                              <td>
                                                <Link
                                                  to={
                                                    "/accounts/" +
                                                    data1.account_id
                                                  }
                                                >
                                                  {" "}
                                                  {data1.account_name}{" "}
                                                </Link>
                                              </td>
                                              <td id="td-right">
                                                <span>
                                                  Opportunity Record Type
                                                </span>
                                              </td>
                                              <td>
                                                {data1.opportunity_record_type}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td id="td-right">
                                                <span>Company</span>
                                              </td>
                                              <td>
                                                <Link
                                                  to={`/company/company-details/${data1.rwood_company_id}`}
                                                >
                                                  {data1.company}
                                                </Link>
                                              </td>
                                              <td id="td-right">
                                                <span>opportunity name</span>
                                              </td>
                                              <td>{data1.opportunity_name}</td>
                                            </tr>
                                            <tr>
                                              <td id="td-right">
                                                <span>currency </span>
                                              </td>
                                              <td>{data1.currency}</td>
                                              <td id="td-right">
                                                <span>opportunity Owner </span>
                                              </td>
                                              <td>{data1.opportunity_owner}</td>
                                            </tr>
                                            <tr>
                                              <td id="td-right">
                                                <span>Exchange Rate </span>
                                              </td>
                                              <td>{data1.exchange_rate}</td>
                                              <td id="td-right">
                                                <span>trader name</span>
                                              </td>
                                              <td>
                                                <Link
                                                  to={
                                                    "/user/user-details/" +
                                                    data1.rwood_users_id
                                                  }
                                                >
                                                  {" "}
                                                  {data1.trader_name}{" "}
                                                </Link>
                                              </td>
                                            </tr>
                                            <tr>
                                              <td id="td-right">
                                                <span>inquiry date </span>
                                              </td>
                                              <td>{data1.inquiry_date}</td>
                                              <td id="td-right">
                                                <span>
                                                  no of trade confirmation
                                                </span>
                                              </td>
                                              <td>
                                                {data1.no_of_trade_confirmation}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td id="td-right">
                                                <span>close date</span>
                                              </td>
                                              <td>{data1.close_date}</td>
                                              <td id="td-right">
                                                {/* <span>close date</span> */}
                                              </td>
                                              <td>
                                                {/* {data1.close_date} */}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td id="td-right">
                                                <span>stage</span>
                                              </td>
                                              <td>{data1.stage}</td>
                                              <td id="td-right">
                                                {/* <span>primary campaign source</span> */}
                                              </td>
                                              <td>
                                                {/* {data1.primary_campaign_source} */}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td id="td-right">
                                                <span>No of Opp Shipments</span>
                                              </td>
                                              <td>
                                                {data1.no_of_opp_shipments}
                                              </td>
                                              <td id="td-right"></td>
                                              <td></td>
                                            </tr>
                                          </table>

                                          <div
                                            className="col-md-12"
                                            id="ax.account_details"
                                          >
                                            <h4 className="heading">
                                              Description Information
                                            </h4>
                                            <hr></hr>
                                          </div>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Origin</span>
                                                </td>
                                                <td>{data1.origin}</td>
                                                <td id="td-right">
                                                  {/* <span>Origin</span> */}
                                                </td>
                                                <td>{/* {data1.origin} */}</td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>laycan year</span>
                                                </td>
                                                <td>{data1.laycan_year}</td>
                                                <td id="td-right">
                                                  {/* <span>laycan year</span> */}
                                                </td>
                                                <td>
                                                  {/* {data1.laycan_year} */}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>commodity</span>
                                                </td>
                                                <td>{data1.commodity}</td>
                                                <td id="td-right">
                                                  {/* <span>commodity</span> */}
                                                </td>
                                                <td>
                                                  {/* {data1.commodity} */}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>
                                                    Bonus penalty clause
                                                  </span>
                                                </td>
                                                <td>
                                                  {data1.bonus_penalty_clause}
                                                </td>
                                                <td id="td-right">
                                                  {/* <span>Bonus penalty clause</span> */}
                                                </td>
                                                <td>
                                                  {/* {data1.bonus_penalty_clause} */}
                                                </td>
                                              </tr>

                                              <tr>
                                                <td id="td-right">
                                                  <span>Payment Terms</span>
                                                </td>
                                                <td>{data1.payment_terms}</td>
                                                <td id="td-right">
                                                  {/* <span>Payment Terms</span> */}
                                                </td>
                                                <td>
                                                  {/* {data1.payment_terms} */}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Rejections</span>
                                                </td>
                                                <td>{data1.rejections}</td>

                                                <td id="td-right">
                                                  {/* <span>Rejections</span> */}
                                                </td>
                                                <td>
                                                  {/* {data1.rejections} */}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Applicable Law</span>
                                                </td>
                                                <td>{data1.applicable_law}</td>
                                                <td id="td-right">
                                                  {/* <span>Applicable Law</span> */}
                                                </td>
                                                <td>
                                                  {/* {data1.applicable_law} */}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>Validity</span>
                                                </td>
                                                <td>{data1.validity}</td>
                                                <td id="td-right">
                                                  {/* <span>Validity</span> */}
                                                </td>
                                                <td>
                                                  {/* {data1.validity} */}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>

                                          <div
                                            className="col-md-12"
                                            id="ax.account_details"
                                          >
                                            <h4 className="heading">
                                              Product Information
                                            </h4>
                                            <hr></hr>
                                          </div>
                                          <table class="table table-bordered account-table tables">
                                            <tbody>
                                              <tr>
                                                <td id="td-right">
                                                <span className="a-a">
                                product description{" "}
                                                <Tooltip text={<span>Product Specification</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                                </td>
                                                <td>
                                                  {data1.product_description}
                                                </td>
                                                <td id="td-right">
                                                  <span>next step</span>
                                                </td>
                                                <td>{data1.next_step}</td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>description</span>
                                                </td>
                                                <td>{data1.description}</td>
                                                <td id="td-right">
                                                  {/* <span>description</span> */}
                                                </td>
                                                <td>
                                                  {/* {data1.description} */}
                                                </td>
                                              </tr>
                                              <tr>
                                                <td id="td-right">
                                                  <span>shipment quantity</span>
                                                </td>
                                                <td>
                                                  {renderShipmentQty(
                                                    data1.quantity
                                                  )}
                                                </td>
                                                <td id="td-right">
                                                  {/* <span>product description</span> */}
                                                </td>
                                                <td>
                                                  {/* {data1.product_description} */}
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>

                                          <Row id="table-styles"></Row>

                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="ax.account_details"
                                            >
                                              <h4 className="heading">
                                                <span>
                                                  Opportunity Shipment
                                                  Information
                                                </span>
                                                <span>
                                                  <Link
                                                    to={
                                                      "/opp/opportunity-shipment/" +
                                                      id
                                                    }
                                                  >
                                                    <i className="fe fe-download-cloud me-2"></i>
                                                    New
                                                  </Link>
                                                </span>
                                              </h4>
                                              {/* <h4 className="heading"><span>Opportunity Shipment Information</span> <span  className="links"><FaPlus/> New</span></h4> */}
                                              <p id="new-offer"></p>
                                              <hr></hr>
                                            </div>
                                            <table
                                              id="shipment"
                                              className="table table-bordered"
                                            >
                                              <thead>
                                                <tr>
                                                  <th>
                                                    Opportunity Shipment Name
                                                  </th>
                                                  <th>Laycan</th>
                                                  <th>Quantity</th>
                                                  <th>Pricing</th>
                                                  <th>Quantity Info</th>
                                                  <th>Vessel Type</th>
                                                  <th>Shipping Term</th>
                                                </tr>
                                              </thead>
                                              {shipments.length > 0 ? (
                                                <tbody>
                                                  {shipments.map((y, i) => (
                                                    <tr>
                                                      <td>
                                                        <span id="codes">
                                                          <Link
                                                            to={
                                                              "/opportunity/oppshipment-detail/" +
                                                              y.id
                                                            }
                                                          >
                                                            {y.opp_ship_code}
                                                          </Link>
                                                        </span>
                                                      </td>
                                                      <td>{y.laycan}</td>
                                                      <td>
                                                        {renderShipmentQty(
                                                          y.quantity
                                                        )}
                                                      </td>
                                                      <td>{y.pricing}</td>
                                                      <td>{y.quantity_info}</td>
                                                      <td>{y.vessel_type}</td>
                                                      <td>{y.shipping_term}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              ) : (
                                                <tbody>
                                                  <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>No data available</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              )}
                                            </table>
                                          </Row>

                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="ax.account_details"
                                            >
                                              <h4 className="heading">
                                                <span>
                                                  Trade Confirmation Forms
                                                </span>{" "}
                                                <span className="links">
                                                  New
                                                </span>
                                              </h4>
                                              <p id="new-offer"></p>
                                              <hr></hr>
                                            </div>
                                            <table
                                              id="tcf"
                                              className="table table-bordered"
                                            >
                                              <thead>
                                                <tr>
                                                  <th>TCF No</th>
                                                  <th>TCF Status </th>
                                                  <th>TCF Date</th>
                                                  <th>Buyer Contract </th>
                                                  <th>Order</th>
                                                  <th>Quote</th>
                                                </tr>
                                              </thead>
                                              {tcf.length > 0 ? (
                                                <tbody>
                                                  {tcf.map((y, i) => (
                                                    <tr>
                                                      <td>
                                                        <span id="codes">
                                                          <Link
                                                            to={
                                                              "/tcf/tcf-details/" +
                                                              y.id
                                                            }
                                                          >
                                                            {y.tcf_no}
                                                          </Link>
                                                        </span>
                                                      </td>
                                                      <td>{y.tcf_status}</td>
                                                      <td>{y.tcf_date}</td>
                                                      <td>
                                                        {y.buyer_contract}
                                                      </td>
                                                      <td>
                                                        <Link
                                                          to={
                                                            "/order/view-order-details/" +
                                                            y.rwood_order_id
                                                          }
                                                        >
                                                          {y.order}
                                                        </Link>
                                                      </td>
                                                      <td>
                                                        <Link
                                                          to={`/opportunity/quote-view/${y.offer_id}`}
                                                        >
                                                          {y.quote}
                                                        </Link>
                                                      </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              ) : (
                                                <tbody>
                                                  <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>No data available</td>
                                                    <td></td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              )}
                                            </table>
                                          </Row>

                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                <span>
                                                  Products (Standard Price Book)
                                                </span>
                                                <span>
                                                  <i className=""></i>
                                                  Add Product
                                                </span>
                                                <span>Choose Price Book</span>
                                                <span>Add Rwood Product</span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="history" className="">
                                              <tr>
                                                <td>
                                                  <p class="no-records">
                                                    No records to display
                                                  </p>
                                                </td>
                                              </tr>
                                            </table>
                                          </Row>

                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                <span>Contact Roles</span>
                                                <span>New</span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="history" className="">
                                              <tr>
                                                <td>
                                                  <p class="no-records">
                                                    No records to display
                                                  </p>
                                                </td>
                                              </tr>
                                            </table>
                                          </Row>

                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                <span>Approval History</span>
                                                <span>Submit for Approval</span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="history" className="">
                                              <tr>
                                                <td>
                                                  <p class="no-records">
                                                    No records to display
                                                  </p>
                                                </td>
                                              </tr>
                                            </table>
                                          </Row>

                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                <span>Competitors</span>
                                                <span>New</span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="history" className="">
                                              <tr>
                                                <td>
                                                  <p class="no-records">
                                                    No records to display
                                                  </p>
                                                </td>
                                              </tr>
                                            </table>
                                          </Row>

                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                <span>Files</span>
                                                <span>Upload File</span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="history" className="">
                                              <tr>
                                                <td>
                                                  <p class="no-records">
                                                    No records to display
                                                  </p>
                                                </td>
                                              </tr>
                                            </table>
                                          </Row>

                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="ax.account_details"
                                            >
                                              <h4 className="heading">
                                                <span>Quotes Information</span>{" "}
                                                <span
                                                  className="links"
                                                  onClick={addOffer}
                                                >
                                                  {" "}
                                                  Create Offer
                                                </span>
                                              </h4>
                                              <hr></hr>
                                              <p id="new-offer"></p>
                                            </div>
                                            <table
                                              id="quotes"
                                              className="table table-bordered"
                                            >
                                              <thead>
                                                <tr>
                                                  <th>Quote Number</th>
                                                  <th>Status</th>
                                                  <th>Quantity MT </th>
                                                  <th>Product Description</th>
                                                  <th>Quote Date</th>
                                                </tr>
                                              </thead>
                                              {datas.length > 0 ? (
                                                <tbody>
                                                  {datas.map((y, i) => (
                                                    <tr>
                                                      <td>
                                                        <Link
                                                          to={
                                                            "/opportunity/quote-view/" +
                                                            y.id
                                                          }
                                                        >
                                                          <span id="codes">
                                                            {y.quote_number}
                                                          </span>
                                                        </Link>
                                                      </td>
                                                      <td>{y.status}</td>
                                                      <td>
                                                        {renderShipmentQty(
                                                          y.quantity_MT
                                                        )}
                                                      </td>
                                                      <td>
                                                        {y.product_description}
                                                      </td>
                                                      <td>{y.quote_date}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              ) : (
                                                <tbody>
                                                  <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>No data available</td>
                                                    <td></td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              )}
                                            </table>
                                          </Row>

                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                <span>Orders</span>
                                                <span>New Order</span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="history" className="">
                                              <tr>
                                                <td>
                                                  <p class="no-records">
                                                    No records to display
                                                  </p>
                                                </td>
                                              </tr>
                                            </table>
                                          </Row>
                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                <span>Notes</span>
                                                <span>
                                                  <i className=""></i>
                                                  New Note
                                                </span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="history" className="">
                                              <tr>
                                                <td>
                                                  <p class="no-records">
                                                    No records to display
                                                  </p>
                                                </td>
                                              </tr>
                                            </table>
                                          </Row>

                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                <span>Open Activities</span>
                                                <span>
                                                  <i className=""></i>
                                                  New Task
                                                </span>
                                                <span>
                                                  <i className=""></i>
                                                  New Event
                                                </span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="history" className="">
                                              <tr>
                                                <td>
                                                  <p class="no-records">
                                                    No records to display
                                                  </p>
                                                </td>
                                              </tr>
                                            </table>
                                          </Row>
                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                <span>Activity History</span>
                                                <span>
                                                  <i className=""></i>
                                                  Log a Call
                                                </span>
                                                <span>
                                                  <i className=""></i>
                                                  Mail merge
                                                </span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="history" className="">
                                              <tr>
                                                <td>
                                                  <p class="no-records">
                                                    No records to display
                                                  </p>
                                                </td>
                                              </tr>
                                            </table>
                                          </Row>
                                          <Row id="table-styles">
                                            <div
                                              className="col-md-12"
                                              id="head"
                                            >
                                              <h4 className="heading">
                                                <span>History Information</span>
                                              </h4>
                                              <hr></hr>
                                            </div>
                                            <table id="historys" className="">
                                              <thead>
                                                <tr>
                                                  <th>Field Name</th>
                                                  <th>New Value</th>
                                                  <th>Old Value</th>
                                                  <th>Changed By</th>
                                                  <th>Change Time</th>
                                                </tr>
                                              </thead>

                                              {history.length > 0 ? (
                                                <tbody>
                                                  {history.map((y, i) => (
                                                    <tr>
                                                      <td>{y.field_name}</td>
                                                      <td>{y.new_value}</td>
                                                      <td>{y.old_value}</td>
                                                      <td>{y.changed_by}</td>
                                                      <td>{y.change_time}</td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              ) : (
                                                <tbody>
                                                  <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td>No data available</td>
                                                    <td></td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              )}
                                            </table>
                                          </Row>
                                          <p></p>
                                          <p></p>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {data1.opportunity_record_type ===
                                      "Supplier" ? (
                                        <>
                                          {isEditMode ? (
                                            <div className="tasks oppEdit">
                                              <div id="messageContainer"></div>
                                              <p className="edit-btn">
                                                <input
                                                  type="submit"
                                                  className="account-save"
                                                  onClick={handleSaveEdit}
                                                  value="Save"
                                                  //disabled={isSubmitted}
                                                />
                                                <button
                                                  className="btn btn-primary"
                                                  onClick={handleCancelEdit}
                                                >
                                                  Cancel
                                                </button>
                                              </p>
                                              <Row className="mb-3">
                                                <h4 className="heading">
                                                  Opportunity Information
                                                </h4>

                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingSelect"
                                                    className="dropDown"
                                                    label="account name"
                                                  >
                                                    <Form.Select
                                                      aria-label="Floating label select example"
                                                      name="account_name"
                                                      placeholder="account name"
                                                      className="error-validation"
                                                      defaultValue={
                                                        data1.account_name
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    >
                                                      <option value="">
                                                        Select
                                                      </option>
                                                      {accountName.map((x) => {
                                                        return (
                                                          <option
                                                            value={
                                                              x.account_name
                                                            }
                                                          >
                                                            {x.account_name}
                                                          </option>
                                                        );
                                                      })}
                                                    </Form.Select>
                                                  </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingSelect"
                                                    className="dropDown"
                                                    label="Company"
                                                  >
                                                    <Form.Select
                                                      aria-label="Floating label select example"
                                                      name="company"
                                                      placeholder="Company"
                                                      defaultValue={
                                                        data1.company || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    >
                                                      <option value="">
                                                        Select
                                                      </option>
                                                      {company.map((x) => {
                                                        return (
                                                          <option
                                                            value={
                                                              x.company_name
                                                            }
                                                          >
                                                            {x.company_name}
                                                          </option>
                                                        );
                                                      })}
                                                    </Form.Select>
                                                  </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                  <label>Locked</label>
                                                  <input
                                                    type="checkbox"
                                                    name="locked"
                                                    checked={lockeds} // Use the state variable to determine if the checkbox is checked
                                                    onChange={
                                                      handleCheckboxChange
                                                    } // Handle the change event
                                                    className="mx-3"
                                                  />
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Exchange rate"
                                                    className="mb-3"
                                                  >
                                                    <Form.Control
                                                      type="text"
                                                      placeholder="Exchange rate"
                                                      name="exchange_rate"
                                                      defaultValue={
                                                        data1.exchange_rate ||
                                                        ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Opportunity record type"
                                                    className="mb-3"
                                                  >
                                                    <Form.Control
                                                      type="text"
                                                      placeholder="Opportunity record type"
                                                      name="opportunity_record_type"
                                                      className="opportunity-record-type"
                                                      defaultValue={
                                                        data1.opportunity_record_type ||
                                                        ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingSelect"
                                                    label="Opportunity name"
                                                    className="select-dropdown"
                                                  >
                                                    <Select
                                                      className="select"
                                                      value={opportunitydrop}
                                                      onChange={
                                                        setOpportunityDropdown
                                                      }
                                                      options={
                                                        optionsOpportunity
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingSelect"
                                                    className="dropDown"
                                                    label="Currency"
                                                  >
                                                    <Form.Select
                                                      aria-label="Floating label select example"
                                                      name="currency"
                                                      placeholder="Currency"
                                                      defaultValue={
                                                        data1.currency || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    >
                                                      <option value="">
                                                        ---None---
                                                      </option>
                                                      <option value="USD">
                                                        USD
                                                      </option>
                                                      <option value="IDR">
                                                        IDR
                                                      </option>
                                                    </Form.Select>
                                                  </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Opportunity Owner"
                                                    className="mb-3"
                                                  >
                                                    <Form.Control
                                                      type="text"
                                                      placeholder="Opportunity Owner"
                                                      name="opportunity_owner"
                                                      defaultValue={
                                                        data1.opportunity_owner ||
                                                        ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="probability"
                                                    className="mb-3"
                                                  >
                                                    <Form.Control
                                                      type="text"
                                                      placeholder="probability"
                                                      name="probability"
                                                      defaultValue={
                                                        data1.probability || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingSelect"
                                                    className="dropDown"
                                                    label="account name"
                                                  >
                                                    <Form.Select
                                                      aria-label="Floating label select example"
                                                      name="account_name"
                                                      placeholder="account name"
                                                      className="error-validation"
                                                      defaultValue={
                                                        data1.account_name
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    >
                                                      <option value="">
                                                        Select
                                                      </option>
                                                      {accountName.map((x) => {
                                                        return (
                                                          <option
                                                            value={
                                                              x.account_name
                                                            }
                                                          >
                                                            {x.account_name}
                                                          </option>
                                                        );
                                                      })}
                                                    </Form.Select>
                                                  </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingSelect"
                                                    className="dropDown"
                                                    label="Trader Name"
                                                  >
                                                    <Form.Select
                                                      aria-label="Floating label select example"
                                                      name="trader_name"
                                                      placeholder="Trader Name"
                                                      defaultValue={
                                                        data1.trader_name || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    >
                                                      <option value="">
                                                        Select
                                                      </option>
                                                      {rwoodusers.map((x) => {
                                                        return (
                                                          <option
                                                            value={
                                                              x.rwood_username
                                                            }
                                                          >
                                                            {x.rwood_username}
                                                          </option>
                                                        );
                                                      })}
                                                    </Form.Select>
                                                  </FloatingLabel>
                                                </Form.Group>

                                                {/* <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Inquiry Date"
                                              className="mb-3 datepick"
                                            >
                                              <DatePicker
                                                selected={startDate1}
                                                onChange={(date) =>
                                                  setStartDate1(date)
                                                }
                                                customInput={<CustomInput />}
                                                dayClassName={() =>
                                                  "example-datepicker-day-class"
                                                }
                                                popperClassName="example-datepicker-class"
                                                todayButton="TODAY"
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Inquiry Date"
                                              />
                                            </FloatingLabel>
                                          </Form.Group> */}

                                                {/* <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="No of trade confirmation"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="No of trade confirmation"
                                                name="no_of_trade_confirmation"
                                                defaultValue={
                                                  data1.no_of_trade_confirmation ||
                                                  ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group> */}

                                                {/* <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Close Date"
                                              className="mb-3 datepick"
                                            >
                                              <DatePicker
                                                selected={startDate2}
                                                onChange={(date) =>
                                                  setStartDate2(date)
                                                }
                                                customInput={<BanktoDate />}
                                                dayClassName={() =>
                                                  "example-datepicker-day-class"
                                                }
                                                popperClassName="example-datepicker-class"
                                                todayButton="TODAY"
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Close Date"
                                              />
                                            </FloatingLabel>
                                          </Form.Group> */}
                                                {/*
                                          <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Loss reason "
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Loss reason"
                                                name="loss_reason"
                                                defaultValue={
                                                  data1.loss_reason || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group> */}
                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingSelect"
                                                    className="dropDown"
                                                    label="Stage"
                                                  >
                                                    <Form.Select
                                                      aria-label="Floating label select example"
                                                      name="stage"
                                                      placeholder="Stage"
                                                      defaultValue={
                                                        data1.stage || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    >
                                                      <option>Select</option>
                                                      <option value="Proposal">
                                                        Proposal
                                                      </option>
                                                      <option value="Concluded">
                                                        Concluded
                                                      </option>
                                                      <option value="Quoted">
                                                        Quoted
                                                      </option>
                                                      <option value="Dropped">
                                                        Dropped
                                                      </option>
                                                      <option value="Last">
                                                        Last
                                                      </option>
                                                      <option value="Closed">
                                                        Closed
                                                      </option>
                                                    </Form.Select>
                                                  </FloatingLabel>
                                                </Form.Group>

                                                {/* <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Primary campaign source"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Primary campaign source"
                                                name="primary_campaign_source"
                                                defaultValue={
                                                  data1.primary_campaign_source ||
                                                  ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group> */}
                                              </Row>

                                              <Row className="mb-3">
                                                <h4 className="heading">
                                                  Description Information
                                                </h4>
                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Origin"
                                                    className="mb-3"
                                                  >
                                                    <Form.Control
                                                      type="text"
                                                      placeholder="Origin"
                                                      name="origin"
                                                      defaultValue={
                                                        data1.origin || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingSelect"
                                                    className="dropDown"
                                                    label="Laycan Year"
                                                  >
                                                    <Form.Select
                                                      aria-label="Floating label select example"
                                                      name="laycan_year"
                                                      placeholder="Laycan Year"
                                                      defaultValue={
                                                        data1.laycan_year || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    >
                                                      <option value="">
                                                        ---None---
                                                      </option>
                                                      <option value="2021">
                                                        2021
                                                      </option>
                                                      <option value="2022">
                                                        2022
                                                      </option>
                                                      <option value="2023">
                                                        2023
                                                      </option>
                                                      <option value="2024">
                                                        2024
                                                      </option>
                                                      <option value="2025">
                                                        2025
                                                      </option>
                                                      <option value="2026">
                                                        2026
                                                      </option>
                                                      <option value="2015">
                                                        2015
                                                      </option>
                                                      <option value="2019">
                                                        2019
                                                      </option>
                                                      <option value="2020">
                                                        2020
                                                      </option>
                                                    </Form.Select>
                                                  </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingSelect"
                                                    className="dropDown"
                                                    label="Commodity"
                                                  >
                                                    <Form.Select
                                                      aria-label="Floating label select example"
                                                      name="commodity"
                                                      placeholder="Commodity"
                                                      defaultValue={
                                                        data1.commodity || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    >
                                                      <option>
                                                        ---None---
                                                      </option>
                                                      <option value='Indonesian Steam Coal in Bulk ("Coal")'>
                                                        Indonesian Steam Coal in
                                                        Bulk ("Coal")
                                                      </option>
                                                      <option value="US Coal">
                                                        US Coal
                                                      </option>
                                                      <option value="Russia Coal">
                                                        Russia Coal
                                                      </option>
                                                      <option value="SA Coal">
                                                        SA Coal
                                                      </option>
                                                      <option value="Clinker in Bulk">
                                                        Clinker in Bulk
                                                      </option>
                                                      <option value="Australian Steam Coal">
                                                        Australian Steam Coal
                                                      </option>
                                                    </Form.Select>
                                                  </FloatingLabel>
                                                </Form.Group>

                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Bonus penalty clause"
                                                    className="mb-3"
                                                  >
                                                    <Form.Control
                                                      type="text"
                                                      placeholder="Bonus penalty clause"
                                                      name="bonus_penalty_clause"
                                                      defaultValue={
                                                        data1.bonus_penalty_clause ||
                                                        ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Rejections"
                                                    className="mb-3"
                                                  >
                                                    <Form.Control
                                                      type="text"
                                                      placeholder="Rejections"
                                                      name="rejections"
                                                      defaultValue={
                                                        data1.rejections || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="12">
                                                  <FloatingLabel
                                                    controlId="floatingTextarea2"
                                                    label="Payment terms"
                                                  >
                                                    <Form.Control
                                                      as="textarea"
                                                      placeholder="Payment terms"
                                                      style={{
                                                        height: "200px",
                                                      }}
                                                      name="payment_terms"
                                                      defaultValue={
                                                        data1.payment_terms ||
                                                        ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingSelect"
                                                    className="dropDown"
                                                    label="Applicable Law"
                                                  >
                                                    <Form.Select
                                                      aria-label="Floating label select example"
                                                      name="applicable_law"
                                                      placeholder="Applicable Law"
                                                      defaultValue={
                                                        data1.applicable_law ||
                                                        ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    >
                                                      <option value="">
                                                        --None--
                                                      </option>
                                                      <option value="English Law">
                                                        English Law
                                                      </option>
                                                      <option value="Singapore Law">
                                                        Singapore Law
                                                      </option>
                                                      <option value="Indonesian Law">
                                                        Indonesian Law
                                                      </option>
                                                    </Form.Select>
                                                  </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Validity"
                                                    className="mb-3"
                                                  >
                                                    <Form.Control
                                                      type="text"
                                                      placeholder="Validity"
                                                      name="validity"
                                                      defaultValue={
                                                        data1.validity || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>
                                              </Row>

                                              <Row className="mb-3">
                                                <h4 className="heading">
                                                  Product Information
                                                </h4>
                                                <Form.Group
                                                  as={Col}
                                                  md="6"
                                                  className="textarea-half tooltips"
                                                >
                                                  <FloatingLabel
                                                    controlId="floatingTextarea2"
                                                    label="Product Description"
                                                  >
                                                    <Form.Control
                                                      as="textarea"
                                                      placeholder="Product Description"
                                                      style={{
                                                        height: "200px",
                                                      }}
                                                      name="product_description"
                                                      defaultValue={
                                                        data1.product_description ||
                                                        ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                  <Tooltip text={<span>Product Specification</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                                </Form.Group>
                                                <Form.Group
                                                  as={Col}
                                                  md="6"
                                                  className="textarea-half"
                                                >
                                                  <FloatingLabel
                                                    controlId="floatingTextarea2"
                                                    label="Description"
                                                  >
                                                    <Form.Control
                                                      as="textarea"
                                                      placeholder="Description"
                                                      style={{
                                                        height: "200px",
                                                      }}
                                                      name="description"
                                                      defaultValue={
                                                        data1.description || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>
                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingInput"
                                                    abel="contract quantity"
                                                    className="mb-3"
                                                  >
                                                    <Form.Control
                                                      type="text"
                                                      placeholder="contract quantity"
                                                      name="quantity_mt"
                                                      defaultValue={
                                                        data1.quantity_mt || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>
                                                {/* <Form.Group as={Col} md="6">
                                            <FloatingLabel
                                              controlId="floatingInput"
                                              label="Amount"
                                              className="mb-3"
                                            >
                                              <Form.Control
                                                type="text"
                                                placeholder="Amount"
                                                name="amount"
                                                defaultValue={
                                                  data1.amount || ""
                                                }
                                                onChange={handleChangeInput}
                                              />
                                            </FloatingLabel>
                                          </Form.Group> */}
                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="shipment quantity "
                                                    className="mb-3"
                                                  >
                                                    <Form.Control
                                                      type="text"
                                                      placeholder="shipment quantity"
                                                      name="quantity"
                                                      defaultValue={
                                                        data1.quantity || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>{" "}
                                                <Form.Group as={Col} md="6">
                                                  <FloatingLabel
                                                    controlId="floatingInput"
                                                    label="Next Step"
                                                    className="mb-3"
                                                  >
                                                    <Form.Control
                                                      type="text"
                                                      placeholder="Next Step"
                                                      name="next_step"
                                                      defaultValue={
                                                        data1.next_step || ""
                                                      }
                                                      onChange={
                                                        handleChangeInput
                                                      }
                                                    />
                                                  </FloatingLabel>
                                                </Form.Group>
                                              </Row>
                                            </div>
                                          ) : (
                                            <div className="tasks">
                                              <div className="edit-delete">
                                                <span
                                                  id="edit"
                                                  onClick={handleEditClick}
                                                >
                                                  Edit
                                                </span>
                                                <span>
                                                  <Link
                                                    to={
                                                      "/opportunity/create-view-list"
                                                    }
                                                  >
                                                    {" "}
                                                    Cancel
                                                  </Link>
                                                </span>
                                                <span>Submit for Approval</span>
                                                <span>New Order</span>
                                              </div>
                                              <div
                                                className="col-md-12"
                                                id="ax.account_details"
                                              >
                                                <h4 className="heading">
                                                  Opportunity Information
                                                </h4>
                                                <hr></hr>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Opportunity No
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {data1.opportunity_no}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        Opportunity Record Type
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {
                                                        data1.opportunity_record_type
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        Exchange Rate{" "}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {data1.exchange_rate}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Company</span>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        to={`/company/company-details/${data1.rwood_company_id}`}
                                                      >
                                                        {data1.company}
                                                      </Link>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        opportunity Owner{" "}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {data1.opportunity_owner}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>
                                                        opportunity name
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {data1.opportunity_name}
                                                    </td>
                                                  </tr>{" "}
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>currency </span>
                                                    </td>
                                                    <td>{data1.currency}</td>
                                                    <td id="td-right">
                                                      <span>probability</span>
                                                    </td>
                                                    <td>{data1.probability}</td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>account name</span>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        to={
                                                          "/accounts/" +
                                                          data1.account_id
                                                        }
                                                      >
                                                        {" "}
                                                        {
                                                          data1.account_name
                                                        }{" "}
                                                      </Link>
                                                    </td>
                                                    <td id="td-right">
                                                      <span>trader name</span>
                                                    </td>
                                                    <td>
                                                      <Link
                                                        to={
                                                          "/user/user-details/" +
                                                          data1.rwood_users_id
                                                        }
                                                      >
                                                        {" "}
                                                        {data1.trader_name}{" "}
                                                      </Link>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>inquiry date </span>
                                                    </td>
                                                    <td>
                                                      {data1.inquiry_date}
                                                    </td>

                                                    <td id="td-right">
                                                      <span>close date</span>
                                                    </td>
                                                    <td>{data1.close_date}</td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        {/* no of trade confirmation */}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {/* {data1.no_of_trade_confirmation} */}
                                                    </td>

                                                    <td id="td-right">
                                                      {/* <span>loss reason </span> */}
                                                    </td>
                                                    <td>
                                                      {/* {data1.loss_reason} */}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>stage</span>
                                                    </td>
                                                    <td>{data1.stage}</td>
                                                    <td id="td-right">
                                                      <span>
                                                        {/* primary campaign source */}
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {/* {data1.primary_campaign_source} */}
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <div
                                                className="col-md-12"
                                                id="ax.account_details"
                                              >
                                                <h4 className="heading">
                                                  Description Information
                                                </h4>
                                                <hr></hr>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Origin</span>
                                                    </td>
                                                    <td>{data1.origin}</td>
                                                    <td id="td-right">
                                                      <span>laycan year</span>
                                                    </td>
                                                    <td>{data1.laycan_year}</td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>commodity</span>
                                                    </td>
                                                    <td>{data1.commodity}</td>
                                                    <td id="td-right">
                                                      <span>
                                                        Bonus penalty clause
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {
                                                        data1.bonus_penalty_clause
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Rejections</span>
                                                    </td>
                                                    <td>{data1.rejections}</td>
                                                    <td id="td-right">
                                                      <span>Payment Terms</span>
                                                    </td>
                                                    <td>
                                                      {data1.payment_terms}
                                                    </td>
                                                  </tr>

                                                  <tr>
                                                    <td id="td-right">
                                                      <span>Validity</span>
                                                    </td>
                                                    <td>{data1.validity}</td>

                                                    <td id="td-right">
                                                      <span>
                                                        Applicable Law
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {data1.applicable_law}
                                                    </td>
                                                    <td></td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <div
                                                className="col-md-12"
                                                id="ax.account_details"
                                              >
                                                <h4 className="heading">
                                                  Product Information
                                                </h4>
                                                <hr></hr>
                                              </div>
                                              <table class="table table-bordered account-table tables">
                                                <tbody>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        contract quantity
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {renderShipmentQty(
                                                        data1.quantity_mt
                                                      )}
                                                    </td>
                                                    <td id="td-right">
                                                      <span>Amount</span>
                                                    </td>
                                                    <td>
                                                      {renderShipmentQty(
                                                        data1.amount
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>
                                                        shipment quantity
                                                      </span>
                                                    </td>
                                                    <td>
                                                      {renderShipmentQty(
                                                        data1.quantity
                                                      )}
                                                    </td>
                                                    <td id="td-right">
                                                    <span className="a-a">
                                product description{" "}
                                                <Tooltip text={<span>Product Specification</span>} placement="left">
      <IoIosHelpCircle id="help" />
      </Tooltip>
                                              </span>
                                                    </td>
                                                    <td>
                                                      {
                                                        data1.product_description
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td id="td-right">
                                                      <span>description</span>
                                                    </td>
                                                    <td>{data1.description}</td>
                                                    <td id="td-right">
                                                      <span>Next Step</span>
                                                    </td>
                                                    <td>{data1.next_step}</td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                              <Row id="table-styles"></Row>
                                              <Row id="table-styles">
                                                <div
                                                  className="col-md-12"
                                                  id="ax.account_details"
                                                >
                                                  <h4 className="heading">
                                                    <span>
                                                      Trade Confirmation Forms
                                                    </span>{" "}
                                                    <span className="links">
                                                      New
                                                    </span>
                                                  </h4>
                                                  <p id="new-offer"></p>
                                                  <hr></hr>
                                                </div>
                                                <table
                                                  id="tcf"
                                                  className="table table-bordered"
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th>TCF No</th>
                                                      <th>TCF Date</th>
                                                      <th>Buyer Contract </th>
                                                      <th>Order</th>
                                                      <th>TCF Status </th>
                                                      <th>Quote</th>
                                                      <th>Quote Line Item</th>
                                                    </tr>
                                                  </thead>
                                                  {tcf.length > 0 ? (
                                                    <tbody>
                                                      {tcf.map((y, i) => (
                                                        <tr>
                                                          <td>
                                                            <span id="codes">
                                                              <Link
                                                                to={
                                                                  "/tcf/tcf-details/" +
                                                                  y.id
                                                                }
                                                              >
                                                                {y.tcf_no}
                                                              </Link>
                                                            </span>
                                                          </td>
                                                          <td>{y.tcf_date}</td>
                                                          <td>
                                                            {y.buyer_contract}
                                                          </td>

                                                          <td>
                                                            <Link
                                                              to={
                                                                "/order/view-order-details/" +
                                                                y.rwood_order_id
                                                              }
                                                            >
                                                              {y.order}
                                                            </Link>
                                                          </td>
                                                          <td>
                                                            {y.tcf_status}
                                                          </td>
                                                          <td>
                                                            <Link
                                                              to={`/opportunity/quote-view/${y.offer_id}`}
                                                            >
                                                              {y.quote}
                                                            </Link>
                                                          </td>
                                                          <td></td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  ) : (
                                                    <tbody>
                                                      <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                          No data available
                                                        </td>
                                                        <td></td>
                                                        <td></td> <td></td>
                                                      </tr>
                                                    </tbody>
                                                  )}
                                                </table>
                                              </Row>
                                              <Row id="table-styles">
                                                <div
                                                  className="col-md-12"
                                                  id="ax.account_details"
                                                >
                                                  <h4 className="heading">
                                                    <span>
                                                      Quotes Information
                                                    </span>{" "}
                                                    <span
                                                      className="links"
                                                      onClick={addOffer}
                                                    >
                                                      {" "}
                                                      Create Offer
                                                    </span>
                                                  </h4>
                                                  <hr></hr>
                                                  <p id="new-offer"></p>
                                                </div>
                                                <table
                                                  id="quotes"
                                                  className="table table-bordered"
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th>Quote Number</th>
                                                      <th>Status</th>
                                                      <th>Quantity MT </th>
                                                      <th>
                                                        Product Description
                                                      </th>
                                                      <th>Quote Date</th>
                                                    </tr>
                                                  </thead>
                                                  {datas.length > 0 ? (
                                                    <tbody>
                                                      {datas.map((y, i) => (
                                                        <tr>
                                                          <td>
                                                            <Link
                                                              to={
                                                                "/opportunity/quote-view/" +
                                                                y.id
                                                              }
                                                            >
                                                              <span id="codes">
                                                                {y.quote_number}
                                                              </span>
                                                            </Link>
                                                          </td>
                                                          <td>{y.status}</td>
                                                          <td>
                                                            {renderShipmentQty(
                                                              y.quantity_MT
                                                            )}
                                                          </td>
                                                          <td>
                                                            {
                                                              y.product_description
                                                            }
                                                          </td>
                                                          <td>
                                                            {y.quote_date}
                                                          </td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  ) : (
                                                    <tbody>
                                                      <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                          No data available
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                      </tr>
                                                    </tbody>
                                                  )}
                                                </table>
                                              </Row>{" "}
                                              <Row id="table-styles">
                                                <div
                                                  className="col-md-12"
                                                  id="ax.account_details"
                                                >
                                                  <h4 className="heading">
                                                    <span>
                                                      Opportunity Shipments
                                                    </span>
                                                    <span>
                                                      <Link
                                                        to={
                                                          "/opp/opportunity-shipment/" +
                                                          id
                                                        }
                                                      >
                                                        <i className="fe fe-download-cloud me-2"></i>
                                                        New
                                                      </Link>
                                                    </span>
                                                  </h4>
                                                  {/* <h4 className="heading"><span>Opportunity Shipment Information</span> <span  className="links"><FaPlus/> New</span></h4> */}
                                                  <p id="new-offer"></p>
                                                  <hr></hr>
                                                </div>
                                                <table
                                                  id="shipment"
                                                  className="table table-bordered"
                                                >
                                                  <thead>
                                                    <tr>
                                                      <th>
                                                        Opportunity Shipment
                                                        Name
                                                      </th>
                                                      <th>Laycan</th>
                                                      <th>Quantity</th>
                                                      <th>Pricing</th>
                                                      <th>Quantity Info</th>
                                                      <th>Vessel Type</th>
                                                      <th>Shipping Term</th>
                                                    </tr>
                                                  </thead>
                                                  {shipments.length > 0 ? (
                                                    <tbody>
                                                      {shipments.map((y, i) => (
                                                        <tr>
                                                          <td>
                                                            <span id="codes">
                                                              <Link
                                                                to={
                                                                  "/opportunity/oppshipment-detail/" +
                                                                  y.id
                                                                }
                                                              >
                                                                {
                                                                  y.opp_ship_code
                                                                }
                                                              </Link>
                                                            </span>
                                                          </td>
                                                          <td>{y.laycan}</td>
                                                          <td>
                                                            {renderShipmentQty(
                                                              y.quantity
                                                            )}
                                                          </td>
                                                          <td>{y.pricing}</td>
                                                          <td>
                                                            {y.quantity_info}
                                                          </td>
                                                          <td>
                                                            {y.vessel_type}
                                                          </td>
                                                          <td>
                                                            {y.shipping_term}
                                                          </td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  ) : (
                                                    <tbody>
                                                      <tr>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                          No data available
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                      </tr>
                                                    </tbody>
                                                  )}
                                                </table>
                                              </Row>
                                              <p></p>
                                              <p></p>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  )}
                                </>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <div className="tasks">
                <h1 className="no-data-detail">
                  <img src="../../images/loading.gif" className="loading" />
                </h1>
              </div>
            )}
          </div>{" "}
        </div>{" "}
      </div>

      <Footer />
    </div>
  );
};

export default ViewOpportunity;
