import React, { useEffect, useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Col, Row } from "react-bootstrap";
import { AES, enc } from 'crypto-js';
import Header from '../Header' 
import Footer from '../Footer' 
import $ from 'jquery';
import { API_URL } from '../config';
import { useParams, Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

function AddDocument() {
  const navigate=useNavigate();
  const params = useParams();
  const id = params.id;
  const body=$(window).height()
  const header=$("#header").height()
  const nav=$(".navbar").height()
  const footer=$(".footer").height()
  const total_height=header+nav+footer;
  const window_height=body-total_height;
  console.log(total_height)
  console.log(body)
  $(".contactAccount").css({"min-height":(window_height)+"px"})
    
    const { register, handleSubmit,control, reset, formState: { errors } } = useForm();
    const onSubmit = data =>{ 
       
      console.log(JSON.stringify(data))
      const encryptedAccessToken = localStorage.getItem('access_token');
        const secret_key = '4d3a23fba54bbef1e14dd44ba9591612';
        if (encryptedAccessToken) {
          const decryptedAccessToken = AES.decrypt(encryptedAccessToken, secret_key).toString(enc.Utf8);
          
        fetch(`${API_URL}add/contract/documents/`+id, {
                  method: "POST",
                  body: JSON.stringify(data),
                  headers: { Authorization: `Bearer ${decryptedAccessToken}`,
                  'Content-Type': 'application/json' },
                })
                  .then((response) => response.json())
                  
                  .then((response) => {  
                    console.log(response)
                    toast.success("Contract Documents added successfully", {
                      position: "bottom-center",
                      autoClose: 3000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      });
                      navigate("/contract/contract-details/" + id);
                    setTimeout(() => { 
                      reset();
                    }, 300); 
                 
                  })
                  
                } 
                 
        };
      
  return (

    <div className="addAccount">
    <Header/>
    <div className="page-header" id='add-account'>
    <div>
      <h2 className="main-content-title tx-24 mg-b-5">Contract Documents </h2>
      <ol className="breadcrumb"> 
        <li className="breadcrumb-item active" aria-current="page">Dashboard / Contract / Contract Documents  </li>
      </ol>
    </div>
    <div className="d-flex">
      <div className="justify-content-center">
      
        {/* <button type="button" className="btn btn-primary my-2 btn-icon-text">
         <a href='/account/add-account'> <i className="fe fe-download-cloud me-2"></i><FaPlus/> Add Account</a>
        </button> */}
        
      </div>
    </div> 
  </div>    <Form onSubmit={handleSubmit(onSubmit)}>
  <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/contract-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

      <Row className="mb-3">
        <h4 className='heading'>Information</h4>
        <Form.Group as={Col} md="6">
                <FloatingLabel
                  controlId="floatingSelect"
                  className="dropDown multiselect"
                  label="list of documents"
                >
                  <Form.Select
                    aria-label="Floating label select example"
                    name="list_of_docuemnts"
                     
                    className=""
                    placeholder="list of documents"
                    {...register("list_of_docuemnts", { required: false })}
                  >
                    <option value="">--None--</option>
                    <option value="Signed Commercial Invoice showing details of calculation">Signed Commercial Invoice showing details of calculation</option>
                    <option value="Clean on board Bills of Lading, marked  Freight payable as per Charter Party">Clean on board Bills of Lading, marked " Freight payable as per Charter Party "</option>
                    <option value="Certificate of Sampling and Analysis issued by Independent Inspecting Authority">Certificate of Sampling and Analysis issued by Independent Inspecting Authority</option>
                    <option value="Certificate of Weight issued by Independent Inspecting Authority">Certificate of Weight issued by Independent Inspecting Authority</option>
                    <option value="Certificate of Draft Survey issued by Independent Inspecting Authority">Certificate of Draft Survey issued by Independent Inspecting Authority</option>
                    <option value="Certificate of Origin issued by Independent Inspecting Authority">Certificate of Origin issued by Independent Inspecting Authority</option>
                    <option value="Certificate Of Hold Cleanliness issued by Independent Inspecting Authority">Certificate Of Hold Cleanliness issued by Independent Inspecting Authority</option>
                    <option value="Signed Provisional Commercial Invoice in triplicate showing actual results and adjustments if applicable">Signed Provisional Commercial Invoice in triplicate showing actual results and adjustments if applicable</option><option value="Certificate of Quality issued by Independent Inspecting Authority">Certificate of Quality issued by Independent Inspecting Authority</option>
                  </Form.Select>
                </FloatingLabel>
              </Form.Group>

        
              <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="original in words"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="original in words"
              
              name="original_in_word"
              {...register("original_in_word", {required: false })}
            />
            {errors.original_in_word  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        
         <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="original in no"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="original in no"
              name="original_in_no"
              {...register("original_in_no", {valueAsNumber:true, required: false })}
            />
            {errors.original_in_no  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="copies in no"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="copies in no"
              name="copies_in_no"
              {...register("copies_in_no", {required: false })}
            />
            {errors.copies_in_no && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
      
        
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Remarks"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Remarks"
              name="remarks"
              // {...register("remarks", {required: false })}
            />
            {/* {errors.remarks  && <span>Required </span>} */}
          </FloatingLabel>
        </Form.Group>
        <Form.Group as={Col} md="6"></Form.Group>
        <Form.Group as={Col} md="6">
          <FloatingLabel
            controlId="floatingInput"
            label="Suffix"
            className="mb-3"
            
            
          >
            <Form.Control
              type="text"
              placeholder="Suffix"
              name="suffix"
              {...register("suffix", {required: false })}
            />
            {errors.suffix  && <span>Required </span>}
          </FloatingLabel>
        </Form.Group>
        
     
             </Row>
             <p className='edit-cancel'><input type="submit" className="account-save" value="Save" /><Link to={`/contract/contract-details/${id}`}><input type="submit" className="account-save" value="Cancel" /></Link></p>

      <div className='successDiv'><p></p><p></p></div>
    </Form>
    <Footer/>
    </div>
  );
}

export default AddDocument;

